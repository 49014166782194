<template>
  <ion-page ref="pageRef">
    <ion-header v-if="track">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button v-if="projectId" :default-href="`/project/${projectId}/${versionId}`"></ion-back-button>
        </ion-buttons>
        <ion-title>Track</ion-title>
        <ion-buttons slot="end">
          <ion-button v-if="!isReadOnly && mode === 'ios'" @click="openModal">
            <ion-icon :icon="cameraOutline"></ion-icon>
          </ion-button>
          <track-actions :projectId="(projectId as string)" :versionId="(versionId as string)" :track="track"
            :goto-url-if-deleted="`/project/${projectId}/${versionId}`">
          </track-actions>
        </ion-buttons>
        <ion-progress-bar v-if="loading === true" type="indeterminate"></ion-progress-bar>
      </ion-toolbar>

      <read-only-alert></read-only-alert>

      <breadcrumbs-toolbar
        v-if="displayBreadcrumbs"
        :projectId="(projectId as string)"
        :versionId="(versionId as string)"
        :trackId="(trackId as string)">
      </breadcrumbs-toolbar>

    </ion-header>
    <ion-content v-if="track">

      <pull-to-refresh></pull-to-refresh>

      <ion-grid v-if="Object.keys(track.items).length === 0 && creating === false"
        style="height: 50%">
        <ion-row class="ion-align-items-center ion-justify-content-center">
          <ion-col class="ion-align-self-center ion-text-center">
            <div class="ion-margin-vertical">
              <ion-label class="ion-text-center">
                There is no pictures in this track.
              </ion-label>
            </div>
            <div v-if="!isReadOnly">
              <ion-button @click="openModal">
                Add a new picture
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid class="project-grid pictures-grid">
        <ion-row ref="sortablePicturesRef">
          <ion-col v-for="picture in picturesSorted" :key="picture.id" size="6"
            :data-project_id="projectId"
            :data-version_id="versionId"
            :data-track_id="track.id"
            :data-picture_id="picture.id"
          >
            <picture-card
              :trackId="track.id"
              :projectId="(projectId as string)"
              :versionId="(versionId as string)"
              :picture="picture"
              :reorderEnabled="reorderEnabled">
            </picture-card>
          </ion-col>

          <ion-col size="6" v-if="creating">
            <ion-card>
              <ion-thumbnail style="width:100%;height:74px;">
                <ion-skeleton-text :animated="true"></ion-skeleton-text>
              </ion-thumbnail>
              <ion-card-header>
                <ion-skeleton-text :animated="true" style="width: 90%;"></ion-skeleton-text>
              </ion-card-header>
              <ion-card-content>
                <ion-skeleton-text :animated="true" style="width: 75%;"></ion-skeleton-text>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-fab v-if="!isReadOnly && mode !== 'ios'" vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="openModal" color="secondary">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted  } from 'vue';
import { createGesture } from '@ionic/vue';
import { getMode } from '@ionic/core';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import Sortable from 'sortablejs';

import { useProjectsStore } from '@/stores/ProjectsStore';
import { useUserStore } from '@/stores/UserStore';

import ProjectUtils from '@/utils/ProjectUtils';

import BreadcrumbsToolbar from '@/components/BreadcrumbsToolbar.vue';
import ReadOnlyAlert from '@/components/ReadOnlyAlert.vue';
import PullToRefresh from '@/components/PullToRefresh.vue';
import NewPictureModal from '../components/modals/NewPictureModal.vue';
import PictureCard from '../components/cards/PictureCard.vue';
import TrackActions from '@/components/actions/TrackActions.vue';

import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonProgressBar,
  IonButtons,
  IonBackButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonButton,
  IonSkeletonText,
  IonThumbnail,
  IonFab,
  IonFabButton,
  IonIcon,
  modalController,
  useIonRouter
} from '@ionic/vue';
import { add, createOutline, trashOutline, cameraOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'TrackPage',
  components: {
    BreadcrumbsToolbar,
    ReadOnlyAlert,
    PullToRefresh,
    PictureCard,
    TrackActions,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonProgressBar,
    IonButtons,
    IonBackButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonLabel,
    IonButton,
    IonSkeletonText,
    IonThumbnail,
    IonFab,
    IonFabButton,
    IonIcon
  },
  setup() {
    const pageRef = ref('pageRef');
    const sortablePicturesRef = ref('sortablePicturesRef');
    const router = useIonRouter();
    const route = useRoute();
    const { projectId, versionId, trackId } = route.params;

    const projectsStore = useProjectsStore();
    const { loading, creating } = storeToRefs(projectsStore);

    const userStore = useUserStore();
    const { isReadOnly, displayBreadcrumbs } = storeToRefs(userStore);

    onMounted(() => {
      // from https://github.com/Sitronik/v-disable-swipe-back
      let gesture: any = null;

      const disableGesture = () => {
          if (gesture) {
              gesture.destroy();
          }

          gesture = null;
      }

      const enableGesture = () => {
        // @ts-ignore
        if (typeof pageRef.value !== 'object' && !pageRef.value.$el) {
            return;
        }

        // @ts-ignore
        gesture = createGesture({
          // @ts-ignore
          el: pageRef.value.$el,
          threshold: 0,
          gestureName: 'goback-swipe',
          gesturePriority: 40.5
        });
        gesture.enable(true);
      }

      // @ts-ignore
      if (typeof sortablePicturesRef.value === 'object' && sortablePicturesRef.value.$el) {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // @ts-ignore
        const sorter = new Sortable(sortablePicturesRef.value.$el, {
          animation: 150,
          delay: 0,
          dragClass: "drag-item",
          ghostClass: "drag-ghost",
          forceFallback: true,
          handle: '.sortable-handle',
          onChoose: () =>{
            enableGesture();
          },
          onEnd: (e:any) => {
            disableGesture();
            const dataset = e.clone.dataset;
            projectsStore.repositionPicture(
              dataset.project_id,
              dataset.version_id,
              dataset.track_id,
              dataset.picture_id,
              e.newIndex + 1
            );
          }
        });
      }
    });

    return {
      pageRef,
      sortablePicturesRef,
      projectId,
      versionId,
      trackId,
      projectsStore,
      isReadOnly,
      displayBreadcrumbs,
      loading,
      creating,
      router,
      add,
      createOutline,
      trashOutline,
      cameraOutline
    };
  },
  /* eslint-disable indent */
  data(): {
    reorderEnabled: boolean,
    mode: any
  } {
    return {
      reorderEnabled: true,
      mode: getMode()
    }
  },
  computed: {
    track() {
      if (this.projectsStore.projects[this.projectId as string] === undefined
        || this.projectsStore.projects[this.projectId as string].versions[this.versionId as string] === undefined
        || this.projectsStore.projects[this.projectId as string].versions[this.versionId as string].tracks[this.trackId as string] === undefined) {
        return null;
      }

      return this.projectsStore.projects[this.projectId as string].versions[this.versionId as string].tracks[this.trackId as string];
    },
    picturesSorted() {
      if (this.track === null) {
        return [];
      }

      return ProjectUtils.sortPictures(this.track.items, '');
    }
  },
  methods: {
    async openModal() {
      if (this.track === null) {
        return;
      }

      const modal = await modalController.create({
        component: NewPictureModal,
        componentProps: {
          projectId: this.projectId,
          versionId: this.versionId,
          trackId: this.trackId
        }
      });
      modal.present();
    }
  }
});
</script>
