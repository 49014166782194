import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "ion-margin-vertical" }
const _hoisted_2 = { style: {"flex-grow":"1","display":"flex","align-content":"center !important"} }
const _hoisted_3 = { class: "ion-text-center ion-margin-vertical" }
const _hoisted_4 = { class: "ion-padding-bottom" }
const _hoisted_5 = { class: "ion-margin-vertical" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_alert = _resolveComponent("ion-alert")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Recently Deleted")
                ]),
                _: 1
              }),
              (_ctx.loading === true)
                ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
                    key: 0,
                    type: "indeterminate"
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleRefresh($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Projects")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deletedProjects, (project, key) => {
                return (_openBlock(), _createBlock(_component_ion_item, { key: key }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(project.title), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_label, {
                      class: "gp-list-item-sub ion-hide-sm-down",
                      slot: "end"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_text, { color: "primary" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Deleted on " + _toDisplayString(new Date(project.deleted_at).toLocaleDateString()), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_button, {
                      id: `project-alert-${key}`,
                      size: "small",
                      slot: "end"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "start",
                          icon: _ctx.refreshCircleOutline
                        }, null, 8, ["icon"]),
                        _createTextVNode(" Restore ")
                      ]),
                      _: 2
                    }, 1032, ["id"]),
                    _createVNode(_component_ion_alert, {
                      trigger: `project-alert-${key}`,
                      header: "Project Restore",
                      buttons: _ctx.alertButtons,
                      message: `Are you sure you want to restore ${project.title}?`,
                      onDidDismiss: ($event: any) => (_ctx.setProjectResult($event, project.id))
                    }, null, 8, ["trigger", "buttons", "message", "onDidDismiss"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }),
          (_ctx.loading === false && Object.keys(_ctx.deletedProjects).length === 0)
            ? (_openBlock(), _createBlock(_component_ion_grid, {
                key: 0,
                class: "project-grid"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, { class: "ion-align-items-center ion-justify-content-center ion-align-items-baseline" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, { class: "ion-align-self-center ion-text-center" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            (Object.keys(_ctx.deletedProjects).length === 0)
                              ? (_openBlock(), _createBlock(_component_ion_label, {
                                  key: 0,
                                  class: "ion-text-center"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" You have no recently deleted projects. ")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Pictures")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.splicedPictures, (pictures, key) => {
                return (_openBlock(), _createBlock(_component_ion_row, { key: key }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pictures, (picture, key2) => {
                      return (_openBlock(), _createBlock(_component_ion_col, {
                        size: "4",
                        key: key2,
                        class: "ion-text-center ion-justify-content-between",
                        style: {"display":"flex !important","flex-direction":"column !important"}
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_ion_img, {
                              src: picture.url.thumbnail,
                              alt: ""
                            }, null, 8, ["src"])
                          ]),
                          _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("div", _hoisted_4, [
                              _createVNode(_component_ion_text, {
                                style: {"font-size":"0.75rem"},
                                color: "primary"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Deleted on " + _toDisplayString(new Date(picture.deleted_at).toLocaleDateString()), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _createElementVNode("div", null, [
                              _createVNode(_component_ion_button, {
                                id: `picture-alert-${key}`,
                                size: "small"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "start",
                                    icon: _ctx.refreshCircleOutline
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" Restore ")
                                ]),
                                _: 2
                              }, 1032, ["id"]),
                              _createVNode(_component_ion_alert, {
                                trigger: `picture-alert-${key}`,
                                header: "Picture restore",
                                buttons: _ctx.alertButtons,
                                message: `Are you sure you want to restore this picture?`,
                                onDidDismiss: ($event: any) => (_ctx.setPictureResult($event, picture.id))
                              }, null, 8, ["trigger", "buttons", "onDidDismiss"])
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }),
          (_ctx.loading === false && Object.keys(_ctx.deletedPictures).length === 0)
            ? (_openBlock(), _createBlock(_component_ion_grid, {
                key: 1,
                class: "project-grid"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, { class: "ion-align-items-center ion-justify-content-center ion-align-items-baseline" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, { class: "ion-align-self-center ion-text-center" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            (Object.keys(_ctx.deletedPictures).length === 0)
                              ? (_openBlock(), _createBlock(_component_ion_label, {
                                  key: 0,
                                  class: "ion-text-center"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" You have no recently deleted pictures. ")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}