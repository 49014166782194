import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Cancel")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (!_ctx.track)
              ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode("New Track")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.track)
              ? (_openBlock(), _createBlock(_component_ion_title, { key: 1 }, {
                  default: _withCtx(() => [
                    _createTextVNode("Edit Track")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                (!_ctx.track)
                  ? (_openBlock(), _createBlock(_component_ion_button, {
                      key: 0,
                      disabled: !_ctx.submittable,
                      strong: true,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirm()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Add")
                      ]),
                      _: 1
                    }, 8, ["disabled"]))
                  : _createCommentVNode("", true),
                (_ctx.track)
                  ? (_openBlock(), _createBlock(_component_ion_button, {
                      key: 1,
                      disabled: !_ctx.submittable,
                      strong: true,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirm()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Edit")
                      ]),
                      _: 1
                    }, 8, ["disabled"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { class: "ion-padding" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_input, {
              type: "text",
              modelValue: _ctx.title,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.title) = $event)),
              modelModifiers: { trim: true },
              autofocus: true,
              inputmode: "text",
              label: "Track title",
              "label-placement": "stacked"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}