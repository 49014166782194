<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Recently Deleted</ion-title>
        <ion-progress-bar v-if="loading === true" type="indeterminate"></ion-progress-bar>
      </ion-toolbar>
    </ion-header>

    <ion-content>

      <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <ion-list>
        <ion-list-header>
          <ion-label>Projects</ion-label>
        </ion-list-header>
        <ion-item v-for="(project, key) in deletedProjects" :key="key">
          <ion-label>{{ project.title }}</ion-label>
          <ion-label class="gp-list-item-sub ion-hide-sm-down" slot="end">
            <ion-text color="primary">Deleted on {{ new Date(project.deleted_at).toLocaleDateString() }}</ion-text>
          </ion-label>
            <ion-button :id="`project-alert-${key}`" size="small" slot="end">
              <ion-icon slot="start" :icon="refreshCircleOutline"></ion-icon>
              Restore
            </ion-button>
            <ion-alert
                :trigger="`project-alert-${key}`"
                header="Project Restore"
                :buttons="alertButtons"
                :message="`Are you sure you want to restore ${project.title}?`"
                @didDismiss="setProjectResult($event, project.id)"
            ></ion-alert>
        </ion-item>
      </ion-list>

      <ion-grid v-if="loading === false && Object.keys(deletedProjects).length === 0"
        class="project-grid">
        <ion-row class="ion-align-items-center ion-justify-content-center ion-align-items-baseline">
          <ion-col class="ion-align-self-center ion-text-center">
            <div class="ion-margin-vertical">
              <ion-label class="ion-text-center" v-if="Object.keys(deletedProjects).length === 0">
                You have no recently deleted projects.
              </ion-label>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>


      <!--
      <ion-list>
        <ion-list-header>
          <ion-label>Version</ion-label>
        </ion-list-header>
        <ion-item v-for="(version, key) in deletedVersions" :key="key">
          <ion-label class="gp-list-item-sub ion-hide-sm-down" slot="end">
            <ion-text color="primary">Deleted on {{ new Date(version.deleted_at).toLocaleDateString() }}</ion-text>
          </ion-label>
          <ion-label class="gp-list-item-sub" slot="end">
            <ion-button :id="`version-alert-${key}`" size="small">
              <ion-icon slot="start" :icon="refreshCircleOutline"></ion-icon>
              Restore
            </ion-button>
            <ion-alert
                :trigger="`version-alert-${key}`"
                header="Version restore"
                :buttons="alertButtons"
                :message="`Are you sure you want to restore ${version.title}?`"
                @didDismiss="setVersionResult($event, version.id)"
            ></ion-alert>
          </ion-label>
          <ion-label>{{ version.title }}</ion-label>
        </ion-item>
      </ion-list>

      <ion-grid v-if="loading === false && Object.keys(deletedVersions).length === 0"
                class="project-grid">
        <ion-row class="ion-align-items-center ion-justify-content-center ion-align-items-baseline">
          <ion-col class="ion-align-self-center ion-text-center">
            <div class="ion-margin-vertical">
              <ion-label class="ion-text-center" v-if="Object.keys(deletedVersions).length === 0">
                You have no recently deleted versions.
              </ion-label>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-list>
        <ion-list-header>
          <ion-label>Tracks</ion-label>
        </ion-list-header>
        <ion-item v-for="(track, key) in deletedTracks" :key="key">
          <ion-label class="gp-list-item-sub ion-hide-sm-down" slot="end">
            <ion-text color="primary">Deleted on {{ new Date(track.deleted_at).toLocaleDateString() }}</ion-text>
          </ion-label>
          <ion-label class="gp-list-item-sub" slot="end">
            <ion-button :id="`track-alert-${key}`" size="small">
              <ion-icon slot="start" :icon="refreshCircleOutline"></ion-icon>
              Restore
            </ion-button>
            <ion-alert
                :trigger="`track-alert-${key}`"
                header="Track restore"
                :buttons="alertButtons"
                :message="`Are you sure you want to restore ${track.title}?`"
                @didDismiss="setTrackResult($event, track.id)"
            ></ion-alert>
          </ion-label>
          <ion-label>{{ track.title }}</ion-label>
        </ion-item>
      </ion-list>

      <ion-grid v-if="loading === false && Object.keys(deletedTracks).length === 0"
        class="project-grid">
        <ion-row class="ion-align-items-center ion-justify-content-center ion-align-items-baseline">
          <ion-col class="ion-align-self-center ion-text-center">
            <div class="ion-margin-vertical">
              <ion-label class="ion-text-center" v-if="Object.keys(deletedTracks).length === 0">
                You have no recently deleted tracks.
              </ion-label>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      -->

      <ion-list>
        <ion-list-header>
          <ion-label>Pictures</ion-label>
        </ion-list-header>
      </ion-list>

      <ion-grid>
        <ion-row v-for="(pictures, key) in splicedPictures" :key="key">
            <ion-col size="4" v-for="(picture, key2) in pictures" :key="key2" class="ion-text-center ion-justify-content-between" style="display:flex !important; flex-direction: column !important;">
              <div style="flex-grow: 1; display: flex; align-content: center !important;">
                <ion-img :src="picture.url.thumbnail" alt=""></ion-img>
              </div>
              <div class="ion-text-center ion-margin-vertical">
                <div class="ion-padding-bottom">
                  <ion-text style="font-size: 0.75rem" color="primary">Deleted on {{ new Date(picture.deleted_at).toLocaleDateString() }}</ion-text>
                </div>
                <div>
                  <ion-button :id="`picture-alert-${key}`" size="small">
                    <ion-icon slot="start" :icon="refreshCircleOutline"></ion-icon>
                    Restore
                  </ion-button>
                  <ion-alert
                      :trigger="`picture-alert-${key}`"
                      header="Picture restore"
                      :buttons="alertButtons"
                      :message="`Are you sure you want to restore this picture?`"
                      @didDismiss="setPictureResult($event, picture.id)"
                  ></ion-alert>
                </div>
              </div>
            </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid v-if="loading === false && Object.keys(deletedPictures).length === 0"
        class="project-grid">
        <ion-row class="ion-align-items-center ion-justify-content-center ion-align-items-baseline">
          <ion-col class="ion-align-self-center ion-text-center">
            <div class="ion-margin-vertical">
              <ion-label class="ion-text-center" v-if="Object.keys(deletedPictures).length === 0">
                You have no recently deleted pictures.
              </ion-label>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import orderBy from 'lodash/orderBy';

import ProjectUtils from '@/utils/ProjectUtils';
import { useProjectsStore } from '@/stores/ProjectsStore';

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonAlert,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonPage,
  IonProgressBar,
  IonRow,
  IonTitle,
  IonToolbar,
  IonText,
  IonImg,
  IonRefresher,
  IonRefresherContent,
  useIonRouter,
} from '@ionic/vue';
import { refreshCircleOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'RecentlyDeletedPage',
  components: {
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonProgressBar,
    IonToolbar,
    IonButtons,
    IonButton,
    IonMenuButton,
    IonLabel,
    IonAlert,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonListHeader,
    IonItem,
    IonText,
    IonImg,
    IonRefresher,
    IonRefresherContent
  },
  setup() {
    const router = useIonRouter();
    const projectsStore = useProjectsStore();
    const { loading, deletedProjects, deletedVersions, deletedTracks, deletedPictures } = storeToRefs(projectsStore);

    const handleRefresh = (event: CustomEvent) => {
      projectsStore.getDeleted();
      setTimeout(() => {
        // @ts-ignore
        event.target.complete();
      }, 2000);
    };

    const alertButtons = [
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'alert-button-danger',
      },
      {
        text: 'OK',
        role: 'confirm',
        cssClass: 'alert-button-confirm'
      },
    ];

    return {
      projectsStore,
      deletedProjects,
      deletedVersions,
      deletedTracks,
      deletedPictures,
      handleRefresh,
      loading,
      router,
      alertButtons,
      refreshCircleOutline
    };
  },
  beforeRouteEnter() {
    const projectsStore = useProjectsStore();
    projectsStore.getDeleted();
  },
  computed: {
    splicedPictures() {
      const sortedPictures = orderBy(this.deletedPictures, ['deleted_at'], ['desc']);
      const spliced = ProjectUtils.spliceIntoChunks(sortedPictures, 3);

      for(let i=0;i<spliced.length;i++) {
        spliced[i] = orderBy(spliced[i], ['deleted_at'], ['desc'])
      }

      return spliced;
    }
  },
  methods: {
    setProjectResult(ev: CustomEvent, projectId: string) {
      if (ev.detail.role == 'confirm') {
        this.projectsStore.restoreDeletedProject(projectId);
      }
    },
    setVersionResult(ev: CustomEvent, versionId: string) {
      if (ev.detail.role == 'confirm') {
        this.projectsStore.restoreDeletedVersion(versionId);
      }
    },
    setTrackResult(ev: CustomEvent, trackId: string) {
      if (ev.detail.role == 'confirm') {
        this.projectsStore.restoreDeletedTrack(trackId);
      }
    },
    setPictureResult(ev: CustomEvent, pictureId: string) {
      if (ev.detail.role == 'confirm') {
        this.projectsStore.restoreDeletedPicture(pictureId);
      }
    }
  }
});
</script>
