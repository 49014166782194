import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "swiper-zoom-container" }
const _hoisted_2 = {
  key: 0,
  class: "swiper-text"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { color: "dark" }, {
        default: _withCtx(() => [
          _createVNode(_component_swiper, {
            initialSlide: _ctx.initialSlide,
            rewind: true,
            zoom: true,
            "slides-per-view": 1,
            "space-between": 50,
            modules: _ctx.modules,
            pagination: {
            dynamicBullets: true,
          }
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pictures, (picture, key) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, { key: key }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_ion_button, {
                        fill: "clear",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close())),
                        style: {"z-index":"2","position":"fixed","top":"10px","right":"0px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            color: "light",
                            icon: _ctx.closeOutline
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }),
                      (picture.description)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(picture.description), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("img", {
                        alt: "",
                        src: picture.url.full
                      }, null, 8, _hoisted_3)
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["initialSlide", "modules"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}