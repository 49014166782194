<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :default-href="`/login`"></ion-back-button>
        </ion-buttons>
        <ion-title>Password Reset</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content id="main-content">
      <ion-grid>
        <ion-row>
          <ion-col size="12" class="ion-text-center">

            <ion-item class="ion-margin-vertical">
              <ion-input type="text" placeholder="email@example.com" ref="inputEmail"
                label="Email" label-placement="stacked"></ion-input>
            </ion-item>

            <ion-button expand="block" @click="resetPassword()">Send a reset email</ion-button>

          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { initializeApp } from 'firebase/app';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { environment } from '@/environments/environment';

import { TOAST_ERROR } from '@/config/config';
import { displayToast } from '@/utils/toast';

import { useUserStore } from '@/stores/UserStore';

import {
  useIonRouter,
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonInput
} from '@ionic/vue';

export default defineComponent({
  name: 'ForgottenPasswordPage',
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonToolbar,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonInput
  },
  setup() {
    const router = useIonRouter();
    const userStore = useUserStore();

    const app = initializeApp(environment.firebase);

    return {
      app,
      userStore,
      router
    }
  },
  methods: {
    async resetPassword() {
      // @ts-ignore
      const email = this.$refs.inputEmail.$el.value;

      if (email === '') {
        return;
      }

      try {
        await FirebaseAuthentication.sendPasswordResetEmail(
          {
            email
          });

        displayToast('An email has been sent to reset your password.');
        if (this.router.canGoBack()) {
          this.router.back();
        } else {
          this.router.push('/login');
        }
      } catch (error) {
        displayToast('An error occurred or no account with this email has been found.', TOAST_ERROR);
      }
    }
  }
});
</script>
