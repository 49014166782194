<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Plugin Authentication</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-button @click="qrcode()" expand="block">
              <ion-icon slot="start" :icon="qrCodeOutline"></ion-icon>
              &nbsp;Authenticate by QR Code</ion-button>

              <div class="ion-text-center ion-margin-top barcode-close-btn ion-hide">
                <ion-button @click="stopScan()" size="small" style="margin: auto;" color="light">
                  <ion-icon slot="start" :icon="closeCircleOutline"></ion-icon> Close camera</ion-button>
              </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <div v-show="showPwaCam">
              <video style="max-width: 100%;" id="video" autoplay></video>
              <div class="ion-text-center">
                <ion-button @click="closePwaCam()" class="ion-margin-top" style="margin: auto;" size="small" fill="clear">
                  <ion-icon slot="start" :icon="closeCircleOutline"></ion-icon> Close camera</ion-button>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-row>
        <ion-col size="12" class="ion-text-center ion-margin-top">
          <ion-text>Or</ion-text>
          <ion-text class="ion-padding-top">
            <h4>Authenticate by code</h4>
          </ion-text>

          <ion-item class="ion-margin-vertical">
            <ion-input type="text" v-model.trim="token"
              label="Code" label-placement="stacked"></ion-input>
          </ion-item>

          <ion-button :disabled="!submittable" @click="tokenAuthenticate()">Authenticate</ion-button>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Capacitor } from '@capacitor/core';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

import { TOAST_ERROR } from '@/config/config';
import { displayToast } from '@/utils/toast';

import UserApi from '@/api/UserApi';
import { useUserStore } from '@/stores/UserStore';

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonText,
  useIonRouter,
} from '@ionic/vue';
import { refreshCircleOutline, qrCodeOutline, closeCircleOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'RecentlyDeletedPage',
  components: {
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonMenuButton,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonInput,
    IonText
  },
  /* eslint-disable indent */
  data(): {
    cameraInterval: number|null,
    /* eslint-disable no-undef */
    // @ts-ignore
    barcodeDetector: BarcodeDetector|null,
    showPwaCam: boolean,
    token: string|null
  } {
    return {
      cameraInterval: null,
      barcodeDetector: null,
      showPwaCam: false,
      token: null
    }
  },
  setup() {
    const router = useIonRouter();
    const userStore = useUserStore();

    return {
      userStore,
      router,
      refreshCircleOutline,
      qrCodeOutline,
      closeCircleOutline
    };
  },
  deactivated() {
    this.stopScan();

    if (this.cameraInterval) {
      clearInterval(this.cameraInterval);
    }
  },
  beforeUnmount() {
    this.stopScan();

    if (this.cameraInterval) {
      clearInterval(this.cameraInterval);
    }
  },
  computed: {
    submittable() {
      return this.token !== undefined && this.token !== null && this.token !== '';
    }
  },
  methods: {
    async tokenAuthenticate() {
      if (!this.submittable || !this.token) {
        return;
      }

     this.validateToken(this.token);
    },
    stopScan() {
      // @ts-ignore
      document.querySelector('body').classList.remove('scanner-active');
      const closeBtn = document.querySelector('.barcode-close-btn');
      if (closeBtn) {
        closeBtn.classList.add('ion-hide');
      }

      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
    },
    async qrcode() {
      if (!Capacitor.isNativePlatform()) {
        return this.qrcodePwa();
      }

      try {
        // check camera permission
        const status = await BarcodeScanner.checkPermission({ force: true });

        if (!status.granted) {
          displayToast('Permission to use the camera has not been granted.', TOAST_ERROR);
          return false;
        }

        // make background of WebView transparent
        BarcodeScanner.hideBackground();

        // @ts-ignore
        document.querySelector('body').classList.add('scanner-active');
        const closeBtn = document.querySelector('.barcode-close-btn');
        if (closeBtn) {
          closeBtn.classList.remove('ion-hide');
        }

        const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

        // if the result has content
        if (result.hasContent) {
          UserApi.validateQrToken(result.content);
        }

        // @ts-ignore
        document.querySelector('body').classList.remove('scanner-active');
        if (closeBtn) {
          closeBtn.classList.add('ion-hide');
        }
      } catch(e) {
        displayToast('Not available on this device or browser.', TOAST_ERROR);
        this.stopScan();
        return false;
      }
    },
    async qrcodePwa() {
      if (this.cameraInterval) {
        clearInterval(this.cameraInterval);
      }

      // @ts-ignore
      try {
        /* eslint-disable no-undef */
        // @ts-ignore
        this.barcodeDetector = new BarcodeDetector({ formats: ['qr_code'] });

        if (!this.barcodeDetector) {
          displayToast('Not available in this browser.', TOAST_ERROR);
          return false;
        }
      } catch(e) {
        displayToast('Not available on this browser.', TOAST_ERROR);
        return false;
      }

      // ----- CAMERA -----

      this.showPwaCam = true;

      // Get the video element
      const video = document.querySelector('#video');
      if (!video) {
        return false;
      }

      // Check if device has camera
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // Use video without audio
        const constraints = {
          video: {
            facingMode: 'environment',
          },
          audio: false
        }

        // Start video stream
        // @ts-ignore
        navigator.mediaDevices.getUserMedia(constraints).then(stream => video.srcObject = stream);
      }

      // ----- BARCODE -----

      // Detect code function
      const detectCode = () => {
        // Start detecting codes on to the video element
        this.barcodeDetector.detect(video).then((codes: string | any[]) => {
          // If no codes exit function
          if (codes.length === 0) return;

          for (const barcode of codes) {
            if (barcode.rawValue) {
              this.showPwaCam = false;
              this.barcodeDetector = null;
              if (this.cameraInterval) {
                clearInterval(this.cameraInterval);
              }
              this.validateToken(barcode.rawValue);
            }
          }
        }).catch(() => {
          // this.showPwaCam = false;
/*          barcodeDetector = null;
          if (this.cameraInterval) {
            clearInterval(this.cameraInterval);
          }*/
        });
      }

      // Run detect code function every 100 milliseconds
      this.cameraInterval = setInterval(detectCode, 250);
    },
    closePwaCam() {
      if (!this.showPwaCam) {
        return;
      }

      this.showPwaCam = false;
      this.barcodeDetector = null;
      if (this.cameraInterval) {
        clearInterval(this.cameraInterval);
      }
    },
    async validateToken(token: string) {
      const result = await UserApi.validateQrToken(token);

      if (!result) {
        displayToast('Plugin authentication has failed. Please try again.', TOAST_ERROR);
        return;
      }

      displayToast('Plugin has been authenticated.');
    }
  }
});
</script>
