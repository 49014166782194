import {
  STORAGE_LAST_SCREEN,
  STORAGE_OPEN_SCREEN_PREFERENCE
} from '@/config/config';

import storage from '@/utils/storage';

const shouldRestore = async (): Promise<boolean> => {
  const restorePreference = await storage.get(STORAGE_OPEN_SCREEN_PREFERENCE);

  return restorePreference === true;
}

const getLastPath = async () => {
  const lastPath = await storage.get(STORAGE_LAST_SCREEN);

  return lastPath || '/';
}

const save = (value: string) => {
  storage.save(STORAGE_LAST_SCREEN, value);
};

const enableRestore = (value: boolean) => {
  storage.save(STORAGE_OPEN_SCREEN_PREFERENCE, value);
}

export default {
  shouldRestore,
  enableRestore,
  getLastPath,
  save,
};
