<template>
  <ion-toolbar class="toolbar-color-mode">
    <ion-segment @ionChange="colorModeChanged($event)" :value="colorMode">
      <ion-segment-button value="auto">
        <ion-label><ion-icon :icon="contrast"></ion-icon><span v-if="labels"> Device (auto)</span></ion-label>
      </ion-segment-button>
      <ion-segment-button value="light">
        <ion-label><ion-icon :icon="sunny"></ion-icon><span v-if="labels"> Light mode</span></ion-label>
      </ion-segment-button>
      <ion-segment-button value="dark">
        <ion-label><ion-icon :icon="moon"></ion-icon> Dark mode</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';

import { useUserStore } from '@/stores/UserStore';

import {
  IonToolbar,
  IonLabel,
  IonIcon,
  IonSegment,
  IonSegmentButton
} from '@ionic/vue';
import { contrast, sunny, moon } from 'ionicons/icons';

export default defineComponent({
  name: 'ColorModeToggle',
  components: {
    IonToolbar,
    IonLabel,
    IonIcon,
    IonSegment,
    IonSegmentButton
  },
  props: {
    labels: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const userStore = useUserStore();
    const { colorMode } = storeToRefs(userStore);

    return {
      userStore,
      colorMode,
      contrast,
      sunny,
      moon
    };
  },
  methods: {
    colorModeChanged(event: any) {
      this.userStore.setColorMode(event.detail.value);
    },
  }
});
</script>

<style scoped>
  ion-toolbar.toolbar-color-mode {
    --background: transparent;
  }
</style>
