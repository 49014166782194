<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Welcome to GearPix</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content id="main-content">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <h4 class="ion-text-center">Social Sign Up</h4>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-button @click="loginGoogle()" expand="block">
              <ion-icon :icon="logoGoogle"></ion-icon>&nbsp;&nbsp;Sign Up with Google
            </ion-button>
            <ion-button @click="loginApple()" expand="block">
              <ion-icon :icon="logoApple"></ion-icon>&nbsp;&nbsp;Sign Up with Apple
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12" class="ion-text-center">
            <ion-text class="ion-margin-bottom">
              <h4>Sign Up with email</h4>
            </ion-text>

            <ion-item class="ion-margin-vertical">
              <ion-input type="text" placeholder="email@example.com" ref="inputEmail"
                label="Email" label-placement="stacked"></ion-input>
            </ion-item>

            <ion-item class="ion-margin-bottom">
              <ion-input type="password" value="" ref="inputPassword"
                label="Password" label-placement="stacked"></ion-input>
            </ion-item>

            <ion-item class="ion-margin-bottom">
              <ion-input type="password" value="" ref="inputPasswordConfirm"
                label="Confirm Password" label-placement="stacked"></ion-input>
            </ion-item>

            <ion-button expand="block" @click="signupEmail()">Sign Up</ion-button>

            <ion-button fill="clear" class="ion-margin-vertical ion-text-center" :router-link="'/login'">Already have an account ? Log in</ion-button>

          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { initializeApp } from 'firebase/app';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { environment } from '@/environments/environment';

import { TOAST_ERROR, TOAST_SUCCESS } from '@/config/config';
import { displayToast } from '@/utils/toast';

import { useUserStore } from '@/stores/UserStore';

import {
  useIonRouter,
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonText
} from '@ionic/vue';
import { logoGoogle, logoApple } from 'ionicons/icons';

export default defineComponent({
  name: 'LoginPage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonIcon,
    IonTitle,
    IonToolbar,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonInput,
    IonText
  },
  setup() {
    const router = useIonRouter();
    const userStore = useUserStore();

    const app = initializeApp(environment.firebase);

    return {
      app,
      userStore,
      router,
      logoGoogle,
      logoApple
    }
  },
  computed: {

  },
  methods: {
    async loginGoogle() {
      try {
        const result = await FirebaseAuthentication.signInWithGoogle();
        const token = await FirebaseAuthentication.getIdToken();

        if (result.user && token.token) {
          await this.userStore.hasLogged(result.user, token.token);
          return;
        }
      } catch (error) {
        return;
      }

      displayToast('An error occurred. Please try again.', TOAST_ERROR);
    },
    async loginApple() {
      try {
        const result = await FirebaseAuthentication.signInWithApple();
        const token = await FirebaseAuthentication.getIdToken();

        if (result.user && token.token) {
          await this.userStore.hasLogged(result.user, token.token);
          return;
        }
      } catch (error) {
        return;
      }

      displayToast('An error occurred. Please try again.', TOAST_ERROR);
    },
    async signupEmail() {
      // @ts-ignore
      const email = this.$refs.inputEmail.$el.value;

      // @ts-ignore
      const password = this.$refs.inputPassword.$el.value;
      // @ts-ignore
      const passwordConfirm = this.$refs.inputPasswordConfirm.$el.value;

      if (email === '' || password === '' || password !== passwordConfirm) {
        displayToast('Your email or password is incorrect. Please try again.', TOAST_ERROR);
        return;
      }

      try {
        const result = await FirebaseAuthentication.createUserWithEmailAndPassword({
          email,
          password
        });

        if (result.user) {
          displayToast('Your account has been successfully created. You can now log in.', TOAST_SUCCESS);
          this.router.push('/login');

        } else {
          displayToast('Your email or password is incorrect. Please try again.', TOAST_ERROR);
        }
      } catch (error) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
      }
    }
  }
});
</script>
