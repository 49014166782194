import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "ion-margin-vertical" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_track_actions = _resolveComponent("track-actions")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_read_only_alert = _resolveComponent("read-only-alert")!
  const _component_breadcrumbs_toolbar = _resolveComponent("breadcrumbs-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_pull_to_refresh = _resolveComponent("pull-to-refresh")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_picture_card = _resolveComponent("picture-card")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { ref: "pageRef" }, {
    default: _withCtx(() => [
      (_ctx.track)
        ? (_openBlock(), _createBlock(_component_ion_header, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, { slot: "start" }, {
                    default: _withCtx(() => [
                      (_ctx.projectId)
                        ? (_openBlock(), _createBlock(_component_ion_back_button, {
                            key: 0,
                            "default-href": `/project/${_ctx.projectId}/${_ctx.versionId}`
                          }, null, 8, ["default-href"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Track")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_buttons, { slot: "end" }, {
                    default: _withCtx(() => [
                      (!_ctx.isReadOnly && _ctx.mode === 'ios')
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 0,
                            onClick: _ctx.openModal
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.cameraOutline }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_track_actions, {
                        projectId: (_ctx.projectId as string),
                        versionId: (_ctx.versionId as string),
                        track: _ctx.track,
                        "goto-url-if-deleted": `/project/${_ctx.projectId}/${_ctx.versionId}`
                      }, null, 8, ["projectId", "versionId", "track", "goto-url-if-deleted"])
                    ]),
                    _: 1
                  }),
                  (_ctx.loading === true)
                    ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
                        key: 0,
                        type: "indeterminate"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_read_only_alert),
              (_ctx.displayBreadcrumbs)
                ? (_openBlock(), _createBlock(_component_breadcrumbs_toolbar, {
                    key: 0,
                    projectId: (_ctx.projectId as string),
                    versionId: (_ctx.versionId as string),
                    trackId: (_ctx.trackId as string)
                  }, null, 8, ["projectId", "versionId", "trackId"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.track)
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_pull_to_refresh),
              (Object.keys(_ctx.track.items).length === 0 && _ctx.creating === false)
                ? (_openBlock(), _createBlock(_component_ion_grid, {
                    key: 0,
                    style: {"height":"50%"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, { class: "ion-align-items-center ion-justify-content-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, { class: "ion-align-self-center ion-text-center" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_1, [
                                _createVNode(_component_ion_label, { class: "ion-text-center" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" There is no pictures in this track. ")
                                  ]),
                                  _: 1
                                })
                              ]),
                              (!_ctx.isReadOnly)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                    _createVNode(_component_ion_button, { onClick: _ctx.openModal }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" Add a new picture ")
                                      ]),
                                      _: 1
                                    }, 8, ["onClick"])
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_grid, { class: "project-grid pictures-grid" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, { ref: "sortablePicturesRef" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.picturesSorted, (picture) => {
                        return (_openBlock(), _createBlock(_component_ion_col, {
                          key: picture.id,
                          size: "6",
                          "data-project_id": _ctx.projectId,
                          "data-version_id": _ctx.versionId,
                          "data-track_id": _ctx.track.id,
                          "data-picture_id": picture.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_picture_card, {
                              trackId: _ctx.track.id,
                              projectId: (_ctx.projectId as string),
                              versionId: (_ctx.versionId as string),
                              picture: picture,
                              reorderEnabled: _ctx.reorderEnabled
                            }, null, 8, ["trackId", "projectId", "versionId", "picture", "reorderEnabled"])
                          ]),
                          _: 2
                        }, 1032, ["data-project_id", "data-version_id", "data-track_id", "data-picture_id"]))
                      }), 128)),
                      (_ctx.creating)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 0,
                            size: "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_thumbnail, { style: {"width":"100%","height":"74px"} }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_skeleton_text, { animated: true })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_skeleton_text, {
                                        animated: true,
                                        style: {"width":"90%"}
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_skeleton_text, {
                                        animated: true,
                                        style: {"width":"75%"}
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              }),
              (!_ctx.isReadOnly && _ctx.mode !== 'ios')
                ? (_openBlock(), _createBlock(_component_ion_fab, {
                    key: 1,
                    vertical: "bottom",
                    horizontal: "end",
                    slot: "fixed"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_fab_button, {
                        onClick: _ctx.openModal,
                        color: "secondary"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 512))
}