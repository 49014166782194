<template>
  <ion-item :router-link="`/project/${project.id}`" button>
    <ion-label class="gp-list-item-sub" slot="end">
      <ion-text color="primary">{{ new Date(project.inserted_at).toLocaleDateString() }}</ion-text>
    </ion-label>
    <project-actions :project="project" :version="version"></project-actions>
    <ion-label>{{ project.title }}</ion-label>
  </ion-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import type { Project } from '@/types/project';
import type { Version } from '@/types/version';

import ProjectUtils from '@/utils/ProjectUtils';

import ProjectActions from '@/components/actions/ProjectActions.vue';

import {
  IonText,
  IonItem,
  IonLabel,
} from '@ionic/vue';

export default defineComponent({
  name: 'ProjectList',
  components: {
    IonLabel,
    IonItem,
    IonText,
    ProjectActions
  },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true
    }
  },
  computed: {
    version(): Version {
      return ProjectUtils.getProjectVersion(this.project);
    },
  },
});
</script>
