import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3794005f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!

  return (_openBlock(), _createBlock(_component_ion_toolbar, { class: "toolbar-color-mode" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_segment, {
        onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.colorModeChanged($event))),
        value: _ctx.colorMode
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_segment_button, { value: "auto" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.contrast }, null, 8, ["icon"]),
                  (_ctx.labels)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, " Device (auto)"))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_segment_button, { value: "light" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.sunny }, null, 8, ["icon"]),
                  (_ctx.labels)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, " Light mode"))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_segment_button, { value: "dark" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.moon }, null, 8, ["icon"]),
                  _createTextVNode(" Dark mode")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    _: 1
  }))
}