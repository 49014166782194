import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7aa875c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-text-center ion-margin-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_color_mode_toggle = _resolveComponent("color-mode-toggle")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, { "content-id": "main-content" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("GearPix")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.user)
        ? (_openBlock(), _createBlock(_component_ion_content, {
            key: 0,
            class: "ion-padding"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_toggle, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_list_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("User")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.logged)
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 0,
                            lines: "none",
                            "router-link": "/account"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.personCircleOutline }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("  " + _toDisplayString(_ctx.user.email), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_item, {
                        lines: "none",
                        "router-link": "/preferences"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.cogOutline }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("  Preferences ")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.logged)
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 1,
                            lines: "none",
                            "router-link": "/auth-plugin"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.qrCodeOutline }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("  Authenticate Plugin ")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.logged)
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 2,
                            lines: "none",
                            "router-link": "/logout"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.logOutOutline }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("  Logout ")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_list_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Projects")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, {
                        lines: "none",
                        "router-link": "/home"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.appsOutline }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("  My Projects ")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, {
                        lines: "none",
                        "router-link": "/deleted"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.trashOutline }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("  Recently Deleted ")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (true === false)
                    ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_list_header, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Help")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, {
                            lines: "none",
                            "router-link": "/"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.phonePortraitOutline }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("  App ")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, {
                            lines: "none",
                            "router-link": "/"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.desktopOutline }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("  Plugin ")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_footer, {
        translucent: true,
        class: "menu-footer ion-no-border"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_color_mode_toggle),
          _createElementVNode("div", _hoisted_1, " GearPix ©" + _toDisplayString(new Date().getFullYear()) + " FunkyBits.fr ", 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}