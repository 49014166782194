import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bec7409"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bc-truncable" }
const _hoisted_2 = { class: "bc-truncable" }
const _hoisted_3 = { class: "bc-truncable" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_breadcrumb = _resolveComponent("ion-breadcrumb")!
  const _component_ion_breadcrumbs = _resolveComponent("ion-breadcrumbs")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!

  return (_openBlock(), _createBlock(_component_ion_toolbar, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_breadcrumbs, {
        "max-items": 3,
        "items-before-collapse": 1,
        "items-after-collapse": 2,
        onIonCollapsedClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.presentPopover($event)), ["stop"]))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_breadcrumb, { "router-link": `/home` }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.home
              }, null, 8, ["icon"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_breadcrumb, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goTo(`/project/${_ctx.project.id}`))),
            "data-url": `/project/${_ctx.project.id}`
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.project.title), 1)
            ]),
            _: 1
          }, 8, ["data-url"]),
          (_ctx.version)
            ? (_openBlock(), _createBlock(_component_ion_breadcrumb, {
                key: 0,
                "router-link": `/project/${_ctx.project.id}/${_ctx.version.id}`
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.version.title), 1)
                ]),
                _: 1
              }, 8, ["router-link"]))
            : _createCommentVNode("", true),
          (_ctx.version && _ctx.track)
            ? (_openBlock(), _createBlock(_component_ion_breadcrumb, {
                key: 1,
                "router-link": `/project/${_ctx.project.id}/${_ctx.version.id}/track/${_ctx.track.id}`
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.track.title), 1)
                ]),
                _: 1
              }, 8, ["router-link"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_popover, {
        "is-open": _ctx.popoverOpen,
        event: _ctx.event,
        onDidDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.popoverOpen = false)),
        size: "auto"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collapsedBreadcrumbs, (breadcrumb, i) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: i,
                      "router-link": `${breadcrumb.dataset.url}`,
                      lines: i === _ctx.collapsedBreadcrumbs.length - 1 ? 'none' : undefined
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createElementVNode("span", {
                              innerHTML: breadcrumb.innerHTML
                            }, null, 8, _hoisted_4)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["router-link", "lines"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["is-open", "event"])
    ]),
    _: 1
  }))
}