<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click="toggleSearch()" fill="clear" size="small">
            <ion-icon :icon="searchOutline"></ion-icon>
          </ion-button>
          <ion-button v-if="!isReadOnly && mode === 'ios'" @click="openNewPictureModal">
            <ion-icon :icon="cameraOutline"></ion-icon>
          </ion-button>
          <ion-button v-if="!isReadOnly && mode === 'ios'" @click="openModal">
            <ion-icon :icon="add"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Projects</ion-title>
        <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
      </ion-toolbar>

      <read-only-alert></read-only-alert>

      <ion-toolbar v-if="searchActive">
        <ion-searchbar :debounce="500" @ionChange="searchTextChange" @ionCancel="cancelSearch" show-cancel-button="always" show-clear-button="always"></ion-searchbar>
      </ion-toolbar>

      <ion-toolbar v-if="!loading">
        <ion-buttons slot="start">
          <ion-button @click="setViewMode('card')">
            <ion-icon
                slot="icon-only"
                :icon="gridOutline"
                :color="viewMode === 'card' ? 'secondary' : ''"></ion-icon>
          </ion-button>
          <ion-button @click="setViewMode('list')">
            <ion-icon
              slot="icon-only"
              :icon="listOutline"
              :color="viewMode === 'list' ? 'secondary' : ''">
            </ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-select interface="popover" slot="end"
          :value="sortBy"
          @ionChange="projectsStore.setSort($event.detail.value)">
          <ion-select-option value="last_active_at_desc">By last active</ion-select-option>
          <ion-select-option value="inserted_at_desc">By creation date desc.</ion-select-option>
          <ion-select-option value="title_asc">By name asc.</ion-select-option>
        </ion-select>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <pull-to-refresh></pull-to-refresh>

      <ion-grid v-if="!loading && !creating
       && Object.keys(projects).length === 0"
        class="project-grid">
        <ion-row class="ion-align-items-center ion-justify-content-center ion-align-items-baseline" style="height: 100%">
          <ion-col class="ion-align-self-center ion-text-center">
            <div class="ion-margin-vertical">
              <ion-label class="ion-text-center" v-if="searchActive === false && Object.keys(projects).length === 0">
                You have not created any project.
              </ion-label>
              <ion-label class="ion-text-center" v-if="searchActive === true && Object.keys(projects).length === 0">
                No projects found.
              </ion-label>
            </div>
            <div v-if="!isReadOnly && searchActive === false">
              <ion-button @click="openModal">
                Create a new project
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-list v-if="viewMode === 'list'">

        <project-list v-for="(project, key) in projectsOrdered" :key="key" :project="project"></project-list>

        <ion-item v-if="creating">
          <ion-skeleton-text :animated="true" slot="end" style="width: 75px"></ion-skeleton-text>
          <ion-skeleton-text :animated="true" slot="end" style="width: 50px"></ion-skeleton-text>
          <ion-skeleton-text :animated="true"></ion-skeleton-text>
        </ion-item>

      </ion-list>

      <ion-grid v-if="viewMode === 'card'" class="project-grid">
        <ion-row>
          <ion-col v-for="(project, key) in projectsOrdered" :key="key" size="6">
            <project-card :projectId="project.id" :version="getProjectVersion(project)"></project-card>
          </ion-col>

          <ion-col size="6" v-if="creating">
            <ion-card>
              <ion-thumbnail style="width:100%;height:74px;">
                <ion-skeleton-text :animated="true"></ion-skeleton-text>
              </ion-thumbnail>
              <ion-card-header>
                <ion-skeleton-text :animated="true" style="width: 90%;"></ion-skeleton-text>
              </ion-card-header>
              <ion-card-content>
                <ion-skeleton-text :animated="true" style="width: 75%;"></ion-skeleton-text>
              </ion-card-content>
            </ion-card>
          </ion-col>

        </ion-row>
      </ion-grid>

      <ion-infinite-scroll @ionInfinite="ionInfinite" class="ion-margin-top">
          <ion-infinite-scroll-content loadingText="Please wait..." loadingSpinner="circles"></ion-infinite-scroll-content>
      </ion-infinite-scroll>

      <ion-fab v-if="!isReadOnly && mode !== 'ios'" vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button color="secondary">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          <ion-fab-button color="secondary" @click="openNewPictureModal" data-desc="Take picture">
            <ion-icon :icon="cameraOutline"></ion-icon>
          </ion-fab-button>
          <ion-fab-button color="secondary" @click="openModal" data-desc="Create project">
            <ion-icon :icon="add"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getMode } from '@ionic/core';
import { storeToRefs } from 'pinia';

import { SearchbarCustomEvent } from '@ionic/core';

import {
  PROJECTS_DEFAULT_VIEW,
  PROJECTS_VIEW_KEY
} from '@/config/config';

import type { Project } from '@/types/project';
import type { Version } from '@/types/version';

import storage from '@/utils/storage';
import ProjectUtils from '@/utils/ProjectUtils';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { useUserStore } from '@/stores/UserStore';

import ReadOnlyAlert from '@/components/ReadOnlyAlert.vue';
import PullToRefresh from '@/components/PullToRefresh.vue';
import ProjectModal from '../components/modals/ProjectModal.vue';
import ProjectCard from '../components/cards/ProjectCard.vue';
import ProjectList from '../components/lists/ProjectList.vue';
import NewPictureModal from '../components/modals/NewPictureModal.vue';

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonProgressBar,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  IonSearchbar,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  modalController,
  useIonRouter,
} from '@ionic/vue';
import { add, gridOutline, listOutline, optionsOutline, searchOutline, cameraOutline } from 'ionicons/icons';
import orderBy from "lodash/orderBy";

export default defineComponent({
  name: 'HomePage',
  components: {
    ReadOnlyAlert,
    PullToRefresh,
    ProjectCard,
    ProjectList,
    IonContent,
    IonFab,
    IonFabButton,
    IonFabList,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonProgressBar,
    IonToolbar,
    IonSearchbar,
    IonButtons,
    IonButton,
    IonMenuButton,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonThumbnail,
    IonSkeletonText,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
  /* eslint-disable indent */
  data(): {
    viewMode: string,
    searchActive: boolean
  } {
    return {
      viewMode: PROJECTS_DEFAULT_VIEW,
      searchActive: false,
    }
  },
  setup() {
    const router = useIonRouter();

    const projectsStore = useProjectsStore();
    const { projects, loading, creating, sortBy } = storeToRefs(projectsStore);

    const userStore = useUserStore();
    const { isReadOnly } = storeToRefs(userStore);

    const mode = getMode();

    return {
      mode,
      projectsStore,
      isReadOnly,
      loading,
      creating,
      projects,
      sortBy,
      router,
      add,
      listOutline,
      gridOutline,
      optionsOutline,
      searchOutline,
      cameraOutline
    };
  },
  async mounted () {
    this.projectsStore.refreshProjects();
    this.viewMode = await storage.get(PROJECTS_VIEW_KEY, PROJECTS_DEFAULT_VIEW);
  },
  computed: {
    projectsOrdered() {
      let sort = 'last_active_at';
      let way: 'asc'|'desc' = 'desc';

      if (this.sortBy === 'inserted_at_desc') {
        sort = 'inserted_at';
      } else if (this.sortBy === 'title_asc') {
        sort = 'title';
        way = 'asc';
      }

      return orderBy(this.projects, [sort], [way]);
    }
  },
  methods: {
    setViewMode(viewMode: string) {
      storage.save(PROJECTS_VIEW_KEY, viewMode);
      this.viewMode = viewMode
    },
    toggleSearch() {
      this.searchActive = !this.searchActive;

      if (!this.searchActive) {
        this.projectsStore.setSearch(null);
      }
    },
    cancelSearch() {
      this.searchActive = false;
      this.projectsStore.setSearch(null);
    },
    searchTextChange(event: SearchbarCustomEvent) {
      if (event.detail.value) {
        const text = event.detail.value.trim().toLowerCase()
        this.projectsStore.setSearch(text);
        return;
      }

      this.projectsStore.setSearch(null);
    },
    async openModal() {
      const modal = await modalController.create({
        component: ProjectModal,
      });
      modal.present();
    },
    async openNewPictureModal() {
      const modal = await modalController.create({
        component: NewPictureModal
      });
      modal.present();
    },
    getProjectVersion(project: Project): Version {
      return ProjectUtils.getProjectVersion(project);
    },
    ionInfinite(event: any) {
      this.projectsStore.getNextPage();
      setTimeout(() => event.target.complete(), 500);
    }
  }
});
</script>
