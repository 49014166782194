import { toastController } from '@ionic/vue';

import {
  TOAST_DURATION, TOAST_SUCCESS
} from '@/config/config';

import { alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';

export const displayToast = async (
  text: string,
  status: 'success' | 'danger' = 'success',
  position: 'top' | 'bottom' | 'middle' | undefined = 'top'
) => {
  const toast = await toastController.create({
    message: text,
    duration: TOAST_DURATION,
    position: position,
    color: status,
    icon: status === TOAST_SUCCESS ? checkmarkCircleOutline : alertCircleOutline
  });

  await toast.present();
};

export default {
  displayToast
};
