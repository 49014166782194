import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_track_actions = _resolveComponent("track-actions")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    "router-link": `/project/${_ctx.projectId}/${_ctx.versionId}/track/${_ctx.track.id}`,
    button: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, {
        class: "gp-list-item-sub",
        slot: "end"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_text, { color: "primary" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(new Date(_ctx.track.inserted_at).toLocaleString()), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_track_actions, {
        projectId: _ctx.projectId,
        versionId: _ctx.versionId,
        track: _ctx.track
      }, null, 8, ["projectId", "versionId", "track"]),
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.track.title), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["router-link"]))
}