<template>
  <ion-button @click.prevent="presentActionSheet()" fill="clear" slot="end" size="default" style="--padding-end: 0;">
    <ion-icon :icon="cogOutline"></ion-icon>
  </ion-button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { storeToRefs } from 'pinia';

import type { Project } from '@/types/project';
import type { Version } from '@/types/version';

import ProjectModal from '@/components/modals/ProjectModal.vue';
import TrackModal from '@/components/modals/TrackModal.vue';
import NewPictureModal from '@/components/modals/NewPictureModal.vue';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { useUserStore } from '@/stores/UserStore';

import {
  IonButton,
  IonIcon,
  actionSheetController,
  alertController,
  modalController,
  // useIonRouter
} from '@ionic/vue';
import { cogOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'ProjectActions',
  components: {
    IonButton,
    IonIcon
  },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true
    },
    version: {
      type: Object as PropType<Version>,
      required: true
    },
    gotoUrlIfDeleted: {
      type: String,
      required: false
    }
  },
  setup() {
    // const router = useIonRouter();
    const projectsStore = useProjectsStore();
    const userStore = useUserStore();
    const { isReadOnly } = storeToRefs(userStore);

    return {
      projectsStore,
      isReadOnly,
      // router,
      cogOutline
    };
  },
  methods: {
    async presentActionSheet() {
      const actionSheet = await actionSheetController.create({
        header: 'Actions',
        cssClass: "gp-action-sheet",
        buttons: [
/*          {
            text: 'View',
            data: {
              action: 'view',
            },
            handler: () => {
              this.router.push(`/project/${project.id}`);
            }
          },*/
          {
            text: 'Add pictures',
            cssClass: this.isReadOnly ? 'action-sheet-disabled' : '',
            data: {
              action: 'edit',
            },
            handler: () => {
              if (this.isReadOnly) {
                return false;
              }

              this.openNewPictureModal();
            }
          },
          {
            text: 'Add track',
            cssClass: this.isReadOnly ? 'action-sheet-disabled' : '',
            data: {
              action: 'edit',
            },
            handler: () => {
              if (this.isReadOnly) {
                return false;
              }

              this.openTrackModal();
            }
          },
          {
            text: 'Edit project',
            cssClass: this.isReadOnly ? 'action-sheet-disabled' : '',
            data: {
              action: 'edit',
            },
            handler: () => {
              if (this.isReadOnly) {
                return false;
              }

              this.openEditModal();
            }
          },
          {
            text: 'Delete project',
            role: 'destructive',
            data: {
              action: 'delete',
            },
            handler: async () => {
              const alert = await alertController.create({
                header: 'Deletion confirmation',
                subHeader: this.project.title,
                message: 'Are you sure you want to delete this project?',
                buttons: [
                  {
                    text: 'NO',
                    role: 'cancel',
                    cssClass: 'alert-button-danger',
                  },
                  {
                    text: 'YES',
                    role: 'confirm',
                    cssClass: 'alert-button-confirm',
                  }
                ],
              });

              await alert.present();

              const { role } = await alert.onDidDismiss();
              if (role === 'confirm') {
                await this.projectsStore.deleteProject(this.project, this.gotoUrlIfDeleted);
                Object.keys(this.projectsStore.projects);
              }
            }
          },
          {
            text: 'Cancel',
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          },
        ],
      });

      await actionSheet.present();
      await actionSheet.onDidDismiss();
    },
    async openEditModal() {
      const modal = await modalController.create({
        component: ProjectModal,
        componentProps: {
          project: this.project
        }
      });
      modal.present();
    },
    async openTrackModal() {
      if (!this.project || !this.version) {
        return;
      }

      const modal = await modalController.create({
        component: TrackModal,
        componentProps: {
          projectId: this.project.id,
          versionId: this.version.id,
        }
      });
      modal.present();
    },
    async openNewPictureModal() {
      const modal = await modalController.create({
        component: NewPictureModal,
        componentProps: {
          projectId: this.project.id,
          versionId: this.version.id,
        }
      });
      modal.present();
    }
  }
});
</script>
