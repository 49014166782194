import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import App from './App.vue'
import router from './router';
import './registerServiceWorker';

import { IonicVue } from '@ionic/vue';

import { STORAGE_UI_MODE, UI_MODE_DEFAULT } from '@/config/config';

import colorMode from '@/utils/colorMode';
import storage from '@/utils/storage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/app.css';

// theme color mode preference
colorMode.applyColorMode();

const pinia = createPinia();

/* eslint-disable no-param-reassign */
pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

// UI style
// inside then() because top level await was not enabled
// not ideal but works, todo: revisit later

(storage.get(STORAGE_UI_MODE)).then((uiMode) => {
  if (!uiMode) {
    uiMode = UI_MODE_DEFAULT;
  }
  const app = createApp(App)
    .use(IonicVue, {
        mode: uiMode,
    })
    .use(router)
    .use(pinia);

  router.isReady().then(() => {
    app.mount('#app');
  });

  defineCustomElements(window);
});



