<template>
  <ion-toolbar v-if="isReadOnly" color="warning">
    <ion-title size="small" style="padding-top: 0; padding-bottom: 0">
      <ion-icon :icon="alertCircleOutline"></ion-icon>&nbsp;&nbsp;Your subscription is inactive. Your are in read-only mode. <a href="#">more</a>
    </ion-title>
  </ion-toolbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';

import { useUserStore } from '@/stores/UserStore';

import {
  IonToolbar,
  IonTitle,
  IonIcon
} from '@ionic/vue';
import { alertCircleOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'ReadOnlyAlert',
  components: {
    IonToolbar,
    IonTitle,
    IonIcon
  },
  setup() {
    const userStore = useUserStore();
    const { isReadOnly } = storeToRefs(userStore);

    return {
      userStore,
      isReadOnly,
      alertCircleOutline
    };
  }
});
</script>
