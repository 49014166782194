<template>
  <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { useProjectsStore } from '@/stores/ProjectsStore';

import {
  IonRefresher,
  IonRefresherContent,
} from '@ionic/vue';

export default defineComponent({
  name: 'PullToRefresh',
  components: {
    IonRefresher,
    IonRefresherContent,
  },
  setup() {
    const projectsStore = useProjectsStore();

    const handleRefresh = (event: CustomEvent) => {
      projectsStore.refreshProjects();
      setTimeout(() => {
        // @ts-ignore
        event.target.complete();
      }, 2000);
    };

    return {
      projectsStore,
      handleRefresh
    };
  }
});
</script>
