<template>
  <ion-card>
    <ion-img :router-link="`/project/${projectId}/${versionId}/track/${trackId}/pictures/${picture.id}`" :src="picture.url.thumbnail" alt=""></ion-img>
    <ion-card-header :router-link="`/project/${projectId}/${versionId}/track/${trackId}/pictures/${picture.id}`">
      <ion-card-subtitle>{{ new Date(picture.inserted_at).toLocaleString() }}</ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      <div class="content-wrapper" >
        <div>
          <div class="description ion-margin-bottom" v-if="picture.description">{{ picture.description }}</div>
        </div>
        <div class="content-actions">
          <div class="sortable-handle" style="padding-top: 4px;">
              <ion-icon :icon="menuOutline" size="large"></ion-icon>
          </div>
          <div>
            <ion-button @click.prevent="openReplaceModal()" fill="clear" slot="end" size="default">
              <ion-icon :icon="cameraReverseOutline"></ion-icon>
            </ion-button>
            <pictures-actions :picture="picture" :trackId="trackId" :projectId="projectId" :versionId="versionId"></pictures-actions>
          </div>
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import type { Picture } from '@/types/picture'

import { useProjectsStore } from '@/stores/ProjectsStore';
import PicturesActions from '@/components/actions/PictureActions.vue';
import EditPictureModal from '@/components/modals/EditPictureModal.vue';
import NewPictureModal from '@/components/modals/NewPictureModal.vue';

import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonImg,
  IonIcon,
  modalController,
  alertController, IonButton
} from '@ionic/vue';
import { menuOutline, cameraReverseOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'PictureCard',
  components: {
    IonButton,
    PicturesActions,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardContent,
    IonImg,
    IonIcon
  },
  props: {
    picture: {
      type: Object as PropType<Picture>,
      required: true
    },
    projectId: {
      type: String,
      required: true
    },
    versionId: {
      type: String,
      required: true
    },
    trackId: {
      type: String,
      required: true
    },
    reorderEnabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const projectsStore = useProjectsStore();

      return {
      projectsStore,
      menuOutline,
      cameraReverseOutline
    };
  },
  methods: {
    async deletePicture() {
      const alert = await alertController.create({
        header: 'Deletion confirmation',
        message: 'Are you sure you want to delete this picture?',
        buttons: [
          {
            text: 'YES',
            role: 'confirm',
            cssClass: 'alert-button-confirm',
          },
          {
            text: 'NO',
            role: 'cancel',
            cssClass: 'alert-button-danger',
          }
        ],
      });

      await alert.present();

      const { role } = await alert.onDidDismiss();
      if (role === 'confirm') {
        this.projectsStore.deletePicture(this.projectId, this.versionId, this.trackId, this.picture);
      }
    },
    async openEditModal() {
      const modal = await modalController.create({
        component: EditPictureModal,
        componentProps: {
          projectId: this.projectId,
          versionId: this.versionId,
          trackId: this.trackId,
          picture: this.picture
        }
      });
      modal.present();
    },
    async openReplaceModal() {
      const modal = await modalController.create({
        component: NewPictureModal,
        componentProps: {
          projectId: this.projectId,
          versionId: this.versionId,
          trackId: this.trackId,
          replacedPicture: this.picture
        }
      });
      modal.present();
    }
  },
});
</script>
