<template>
  <ion-page>
    <ion-header v-if="project && version">

      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/home"></ion-back-button>
        </ion-buttons>

        <ion-buttons slot="end">
          <ion-button v-if="!isReadOnly && mode === 'ios'" @click="openNewPictureModal">
            <ion-icon :icon="cameraOutline"></ion-icon>
          </ion-button>
          <ion-button v-if="!isReadOnly && mode === 'ios'" @click="openModal">
            <ion-icon :icon="add"></ion-icon>
          </ion-button>
          <project-actions :project="project" :version="version" :goto-url-if-deleted="'/'"></project-actions>
        </ion-buttons>
        <ion-title>Project</ion-title>
        <ion-progress-bar v-if="loading === true" type="indeterminate"></ion-progress-bar>
      </ion-toolbar>

      <read-only-alert></read-only-alert>

      <breadcrumbs-toolbar v-if="displayBreadcrumbs" :projectId="project.id" :versionId="version.id"></breadcrumbs-toolbar>

      <ion-toolbar class="version-toolbar" v-if="loading === false && (versionToolbar === true || displayVersionToolbar)">
        <ion-label slot="start" class="ion-padding-start">Versions</ion-label>
        <ion-select interface="popover" slot="end"
                    :value="version.id"
                    @ionChange="setVersion($event.detail.value)">
          <ion-select-option v-for="(v, key) in project.versions" :key="key" :value="v.id">
            {{ v.title }}
          </ion-select-option>
        </ion-select>
        <version-actions v-if="hasFeature('versioning')"
          :project="project" :version="version" :goto-url-if-deleted="`/project/${id}`"></version-actions>
        <ion-button id="version-click-trigger" v-if="!hasFeature('versioning')"
          fill="clear" size="small" slot="end">
          <ion-icon slot="icon-only" :icon="informationCircleOutline"></ion-icon>
        </ion-button>
        <ion-popover trigger="version-click-trigger" trigger-action="click" v-if="!hasFeature('versioning')">
          <ion-content class="ion-padding">Versioning is not active with your current subscription plan.</ion-content>
        </ion-popover>
      </ion-toolbar>

      <ion-toolbar v-if="loading === false">
        <ion-buttons slot="start">
          <ion-button @click="setViewMode('card')">
            <ion-icon
                slot="icon-only"
                :icon="gridOutline"
                :color="viewMode === 'card' ? 'secondary' : ''"></ion-icon>
          </ion-button>
          <ion-button @click="setViewMode('list')">
            <ion-icon
                :icon="listOutline"
                :color="viewMode === 'list' ? 'secondary' : ''">
            </ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title color="medium" v-if="!displayVersionToolbar">
          <ion-button @click="toggleVersionToolbar()" fill="clear" size="small">
            <ion-icon
                slot="icon-only"
                :icon="gitMergeOutline"
                :color="versionToolbar ? 'secondary' : ''">
            </ion-icon>
          </ion-button>
        </ion-title>
        <ion-select interface="popover" slot="end"
                    :value="sortBy"
                    @ionChange="setSortBy($event.detail.value)">
          <ion-select-option value="last_active_at_desc">By last active</ion-select-option>
          <ion-select-option value="inserted_at_desc">By creation date desc.</ion-select-option>
          <ion-select-option value="title_asc">By name asc.</ion-select-option>
        </ion-select>
      </ion-toolbar>

    </ion-header>

    <ion-content v-if="project && version">
      <pull-to-refresh></pull-to-refresh>

      <ion-grid v-if="Object.keys(version.tracks).length === 0 && !creating"
        class="project-grid">
        <ion-row class="ion-align-items-center ion-justify-content-center ion-align-items-baseline">
          <ion-col class="ion-align-self-center ion-text-center">
            <div class="ion-margin-vertical">
              <ion-label class="ion-text-center">
              There is no tracks in this project.
            </ion-label>
            </div>
            <div v-if="!isReadOnly">
              <ion-button @click="openModal">
                Add a new track
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-list v-if="viewMode === 'list'">
        <track-list v-for="(track, key) in tracksOrdered" :key="key"
          :projectId="id" :versionId="version.id" :track="track"></track-list>
      </ion-list>

      <ion-grid  v-if="viewMode === 'card'" class="project-grid">
        <ion-row>
          <ion-col v-for="(track, key) in tracksOrdered" :key="key" size="6">
            <track-card :trackId="track.id" :projectId="(id as string)" :versionId="version.id"></track-card>
          </ion-col>

          <ion-col size="6" v-if="creating">
            <ion-card>
              <ion-thumbnail style="width:100%;height:74px;">
                <ion-skeleton-text :animated="true"></ion-skeleton-text>
              </ion-thumbnail>
              <ion-card-header>
                <ion-skeleton-text :animated="true" style="width: 90%;"></ion-skeleton-text>
              </ion-card-header>
              <ion-card-content>
                <ion-skeleton-text :animated="true" style="width: 75%;"></ion-skeleton-text>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-fab v-if="!isReadOnly && mode !== 'ios'" vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button color="secondary">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          <ion-fab-button color="secondary" @click="openNewPictureModal" data-desc="Take picture">
            <ion-icon :icon="cameraOutline"></ion-icon>
          </ion-fab-button>
          <ion-fab-button color="secondary" @click="openModal" data-desc="Create track">
            <ion-icon :icon="add"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, watch, ref } from 'vue';
import { getMode } from '@ionic/core';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import orderBy from 'lodash/orderBy';

import {
  TRACKS_DEFAULT_SORT,
  TRACKS_SORT_KEY,
  TRACKS_DEFAULT_VIEW,
  TRACKS_VIEW_KEY
} from '@/config/config';

import storage from '@/utils/storage';
import ProjectUtils from '@/utils/ProjectUtils';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { useUserStore } from '@/stores/UserStore';

import BreadcrumbsToolbar from '@/components/BreadcrumbsToolbar.vue';
import ReadOnlyAlert from '@/components/ReadOnlyAlert.vue';
import PullToRefresh from '@/components/PullToRefresh.vue';
import ProjectActions from '@/components/actions/ProjectActions.vue';
import VersionActions from '@/components/actions/VersionActions.vue';
import TrackModal from '@/components/modals/TrackModal.vue';
import TrackCard from '@/components/cards/TrackCard.vue';
import TrackList from '@/components/lists/TrackList.vue';
import NewPictureModal from '../components/modals/NewPictureModal.vue';

import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonProgressBar,
  IonButtons,
  IonBackButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonButton,
  IonSkeletonText,
  IonThumbnail,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonList,
  modalController,
  IonSelect,
  IonSelectOption,
  IonPopover,
  useIonRouter
} from '@ionic/vue';
import { add, gitMergeOutline, gridOutline, listOutline,
  cameraOutline, informationCircleOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'ProjectPage',
  components: {
    ReadOnlyAlert,
    BreadcrumbsToolbar,
    PullToRefresh,
    ProjectActions,
    VersionActions,
    TrackCard,
    TrackList,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonProgressBar,
    IonButtons,
    IonBackButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonLabel,
    IonButton,
    IonSkeletonText,
    IonThumbnail,
    IonFab,
    IonFabButton,
    IonFabList,
    IonIcon,
    IonList,
    IonSelect,
    IonSelectOption,
    IonPopover
  },
  /* eslint-disable indent */
  data(): {
    viewMode: string,
    sortBy: string,
    versionToolbar: boolean,
  } {
    return {
      viewMode: TRACKS_DEFAULT_VIEW,
      sortBy: TRACKS_DEFAULT_SORT,
      versionToolbar: false
    }
  },
  setup() {
    const router = useIonRouter();
    const route = useRoute();
    const { id } = route.params;

    const versionId = ref();
    if (route.params.versionId) {
      versionId.value = route.params.versionId;
    }

    const projectsStore = useProjectsStore();
    const { loading, creating, projects } = storeToRefs(projectsStore);

    const userStore = useUserStore();
    const { isReadOnly, hasFeature, displayBreadcrumbs, displayVersionToolbar } = storeToRefs(userStore);

    const mode = getMode();

    watch(
        () => route.params.versionId,
        async newVersionId => {
          versionId.value = newVersionId;
        }
    )

    return {
      mode,
      id,
      versionId,
      projectsStore,
      isReadOnly,
      hasFeature,
      displayBreadcrumbs,
      displayVersionToolbar,
      loading,
      creating,
      projects,
      router,
      add,
      gitMergeOutline,
      gridOutline,
      listOutline,
      cameraOutline,
      informationCircleOutline
    };
  },
  async mounted () {
    this.viewMode = await storage.get(TRACKS_VIEW_KEY, TRACKS_DEFAULT_VIEW);
    this.sortBy = await storage.get(TRACKS_SORT_KEY, TRACKS_DEFAULT_SORT);
  },
  computed: {
    project() {
      if (!this.projects[this.id as string]) {
        return null;
      }

      return this.projects[this.id as string];
    },
    version() {
      if (!this.project ) {
        return null;
      }

      if (this.versionId && this.project.versions[this.versionId as string]) {
        return this.project.versions[this.versionId as string];
      }

      return ProjectUtils.getProjectVersion(this.project);
    },
    tracksOrdered() {
      if (!this.project || !this.version) {
        return [];
      }

      let sort = 'last_active_at';
      let way: 'asc'|'desc' = 'desc';

      if (this.sortBy === 'inserted_at_desc') {
        sort = 'inserted_at';
      } else if (this.sortBy === 'title_asc') {
        sort = 'title';
        way = 'asc';
      }

      return orderBy(this.version.tracks, [sort], [way]);
    }
  },
  methods: {
    setViewMode(viewMode: string) {
      storage.save(TRACKS_VIEW_KEY, viewMode);
      this.viewMode = viewMode
    },
    setSortBy(sortBy: string) {
      storage.save(TRACKS_SORT_KEY, sortBy);
      this.sortBy = sortBy;
    },
    toggleVersionToolbar() {
      this.versionToolbar = !this.versionToolbar;
    },
    setVersion(versionId: string) {
      if (!this.project) {
        return;
      }

      this.router.push(`/project/${this.id}/${versionId}`);
    },
    async openModal() {
      if (!this.project) {
        return;
      }

      const modal = await modalController.create({
        component: TrackModal,
        componentProps: {
          projectId: this.id,
          versionId: this.version ? this.version.id : null
        }
      });
      modal.present();
    },
    async openNewPictureModal() {
      if (!this.project) {
        return;
      }

      const modal = await modalController.create({
        component: NewPictureModal,
        componentProps: {
          projectId: this.id,
          versionId: this.version ? this.version.id : null
        }
      });
      modal.present();
    },
  }
});
</script>

<style scoped>
  ion-toolbar.version-toolbar {
    --min-height: 36px;
  }
</style>
