<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="cancel()">Cancel</ion-button>
      </ion-buttons>
      <ion-title v-if="!track">New Track</ion-title>
      <ion-title v-if="track">Edit Track</ion-title>
      <ion-buttons slot="end">
        <ion-button v-if="!track" :disabled="!submittable" :strong="true" @click="confirm()">Add</ion-button>
        <ion-button v-if="track" :disabled="!submittable" :strong="true" @click="confirm()">Edit</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-item>
      <ion-input type="text" v-model.trim="title" :autofocus="true" inputmode="text"
        label="Track title" label-placement="stacked"></ion-input>
    </ion-item>
  </ion-content>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import type { PropType } from 'vue';
import { storeToRefs } from 'pinia';

import type { Track } from '@/types/track';

import { useProjectsStore } from '@/stores/ProjectsStore';

import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem,
  IonInput,
  modalController
} from '@ionic/vue';

export default defineComponent({
  name: 'TrackModal',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonItem,
    IonInput
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    versionId: {
      type: String,
      required: true
    },
    track: {
      type: Object as PropType<Track>,
      required: false
    }
  },
  setup(props) {
    const projectsStore = useProjectsStore();
    const { creating } = storeToRefs(projectsStore);

    // eslint-disable-next-line vue/no-setup-props-destructure
    const title = ref(props.track ? props.track.title : null);

    return {
      projectsStore,
      creating,
      title
    };
  },
  computed: {
    submittable() {
      return this.title !== undefined && this.title !== null && this.title !== '';
    }
  },
  methods: {
    cancel() {
      return modalController.dismiss(null, 'cancel');
    },
    async confirm() {
      if (this.creating) {
        return;
      }

      if (!this.title || this.title === '') {
        return;
      }

      let trackId: string|undefined;
      if (this.track) {
        this.projectsStore.editTrack(this.projectId, this.versionId, this.track, this.title);
      } else {
        trackId = await this.projectsStore.createTrack(this.projectId, this.versionId, this.title);
      }

      return modalController.dismiss(trackId, 'confirm');
    },
  },
});
</script>
