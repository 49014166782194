import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "ion-margin-vertical" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_read_only_alert = _resolveComponent("read-only-alert")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_pull_to_refresh = _resolveComponent("pull-to-refresh")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_project_list = _resolveComponent("project-list")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_project_card = _resolveComponent("project-card")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab_list = _resolveComponent("ion-fab-list")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSearch())),
                    fill: "clear",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.searchOutline }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }),
                  (!_ctx.isReadOnly && _ctx.mode === 'ios')
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        onClick: _ctx.openNewPictureModal
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.cameraOutline }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (!_ctx.isReadOnly && _ctx.mode === 'ios')
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        onClick: _ctx.openModal
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Projects")
                ]),
                _: 1
              }),
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
                    key: 0,
                    type: "indeterminate"
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_read_only_alert),
          (_ctx.searchActive)
            ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_searchbar, {
                    debounce: 500,
                    onIonChange: _ctx.searchTextChange,
                    onIonCancel: _ctx.cancelSearch,
                    "show-cancel-button": "always",
                    "show-clear-button": "always"
                  }, null, 8, ["onIonChange", "onIonCancel"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.loading)
            ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, { slot: "start" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setViewMode('card')))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            icon: _ctx.gridOutline,
                            color: _ctx.viewMode === 'card' ? 'secondary' : ''
                          }, null, 8, ["icon", "color"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_button, {
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setViewMode('list')))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            icon: _ctx.listOutline,
                            color: _ctx.viewMode === 'list' ? 'secondary' : ''
                          }, null, 8, ["icon", "color"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select, {
                    interface: "popover",
                    slot: "end",
                    value: _ctx.sortBy,
                    onIonChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.projectsStore.setSort($event.detail.value)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select_option, { value: "last_active_at_desc" }, {
                        default: _withCtx(() => [
                          _createTextVNode("By last active")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "inserted_at_desc" }, {
                        default: _withCtx(() => [
                          _createTextVNode("By creation date desc.")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, { value: "title_asc" }, {
                        default: _withCtx(() => [
                          _createTextVNode("By name asc.")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_pull_to_refresh),
          (!_ctx.loading && !_ctx.creating
       && Object.keys(_ctx.projects).length === 0)
            ? (_openBlock(), _createBlock(_component_ion_grid, {
                key: 0,
                class: "project-grid"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, {
                    class: "ion-align-items-center ion-justify-content-center ion-align-items-baseline",
                    style: {"height":"100%"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, { class: "ion-align-self-center ion-text-center" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            (_ctx.searchActive === false && Object.keys(_ctx.projects).length === 0)
                              ? (_openBlock(), _createBlock(_component_ion_label, {
                                  key: 0,
                                  class: "ion-text-center"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" You have not created any project. ")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (_ctx.searchActive === true && Object.keys(_ctx.projects).length === 0)
                              ? (_openBlock(), _createBlock(_component_ion_label, {
                                  key: 1,
                                  class: "ion-text-center"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" No projects found. ")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          (!_ctx.isReadOnly && _ctx.searchActive === false)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                _createVNode(_component_ion_button, { onClick: _ctx.openModal }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Create a new project ")
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.viewMode === 'list')
            ? (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectsOrdered, (project, key) => {
                    return (_openBlock(), _createBlock(_component_project_list, {
                      key: key,
                      project: project
                    }, null, 8, ["project"]))
                  }), 128)),
                  (_ctx.creating)
                    ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_skeleton_text, {
                            animated: true,
                            slot: "end",
                            style: {"width":"75px"}
                          }),
                          _createVNode(_component_ion_skeleton_text, {
                            animated: true,
                            slot: "end",
                            style: {"width":"50px"}
                          }),
                          _createVNode(_component_ion_skeleton_text, { animated: true })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.viewMode === 'card')
            ? (_openBlock(), _createBlock(_component_ion_grid, {
                key: 2,
                class: "project-grid"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectsOrdered, (project, key) => {
                        return (_openBlock(), _createBlock(_component_ion_col, {
                          key: key,
                          size: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_project_card, {
                              projectId: project.id,
                              version: _ctx.getProjectVersion(project)
                            }, null, 8, ["projectId", "version"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      (_ctx.creating)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 0,
                            size: "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_thumbnail, { style: {"width":"100%","height":"74px"} }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_skeleton_text, { animated: true })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_skeleton_text, {
                                        animated: true,
                                        style: {"width":"90%"}
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_skeleton_text, {
                                        animated: true,
                                        style: {"width":"75%"}
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_infinite_scroll, {
            onIonInfinite: _ctx.ionInfinite,
            class: "ion-margin-top"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_infinite_scroll_content, {
                loadingText: "Please wait...",
                loadingSpinner: "circles"
              })
            ]),
            _: 1
          }, 8, ["onIonInfinite"]),
          (!_ctx.isReadOnly && _ctx.mode !== 'ios')
            ? (_openBlock(), _createBlock(_component_ion_fab, {
                key: 3,
                vertical: "bottom",
                horizontal: "end",
                slot: "fixed"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_fab_button, { color: "secondary" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_fab_list, { side: "top" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_fab_button, {
                        color: "secondary",
                        onClick: _ctx.openNewPictureModal,
                        "data-desc": "Take picture"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.cameraOutline }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_ion_fab_button, {
                        color: "secondary",
                        onClick: _ctx.openModal,
                        "data-desc": "Create project"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}