import { defineStore } from 'pinia'
import { nextTick } from 'vue';

import {
  STORAGE_TOKEN,
  STORAGE_DISPLAY_BREADCRUMBS,
  STORAGE_DISPLAY_VERSION,
  STORAGE_SAVE_PICTURES_TO_GALLERY,
  DISPLAY_BREADCRUMBS_DEFAULT,
  DISPLAY_VERSION_DEFAULT,
  SAVE_PICTURES_TO_GALLERY,
  COLOR_MODE_DEFAULT,
  STORAGE_COLOR_MODE,
  LAST_SCREEN_DEFAULT,
  STORAGE_UI_MODE,
  UI_MODE_DEFAULT,
  TOAST_ERROR
} from '@/config/config';

import { environment } from '@/environments/environment';

import UserApi from '../api/UserApi';
import storage from '../utils/storage';
import lastPage from '../utils/lastPage';
import { displayToast } from '@/utils/toast';
// @ts-ignore
import { Socket } from '../vendors/phoenix/index';

import { useProjectsStore } from '@/stores/ProjectsStore';

import type { User } from '@/types/user';
import type { Product } from '@/types/product';
import type { Plan } from '@/types/plan';
import userApi from '../api/UserApi';

interface State {
  me: any,
  user: User|null,
  product: Product|null,
  plan: Plan|null,
  features: Record<string, boolean>|null,
  logged: boolean,
  token: any,
  socket: any,
  channel: any,
  uiMode: string,
  displayBreadcrumbs: boolean,
  displayVersionToolbar: boolean,
  savePicturesToGallery: boolean,
  colorMode: string,
  reopenAtLaunch: boolean
}

export const useUserStore = defineStore('user', {
  state: (): State => (
    {
      me: null,
      user: null,
      product: null,
      plan: null,
      features: null,
      token: null,
      logged: false,
      socket: null,
      channel: null,
      uiMode: UI_MODE_DEFAULT,
      displayBreadcrumbs: DISPLAY_BREADCRUMBS_DEFAULT,
      displayVersionToolbar: DISPLAY_VERSION_DEFAULT,
      savePicturesToGallery: SAVE_PICTURES_TO_GALLERY,
      colorMode: COLOR_MODE_DEFAULT,
      reopenAtLaunch: LAST_SCREEN_DEFAULT
    }
  ),
  getters: {
    isReadOnly(): boolean {
      if (!this.plan) {
        return true;
      }

      if (!this.plan.active_until) {
        return false;
      }

      const now = new Date();
      const activeUntil = new Date(this.plan.active_until);

      return now > activeUntil;
    },
    hasFeature: (state) => {
      return (feature: string) => (state.features !== null && state.features[feature] === true);
    }
  },
  actions: {
    async init() {
      this.uiMode = await storage.get(STORAGE_UI_MODE, UI_MODE_DEFAULT);
      this.displayBreadcrumbs = await storage.get(STORAGE_DISPLAY_BREADCRUMBS, DISPLAY_BREADCRUMBS_DEFAULT);
      this.displayVersionToolbar = await storage.get(STORAGE_DISPLAY_VERSION, DISPLAY_VERSION_DEFAULT);
      this.savePicturesToGallery = await storage.get(STORAGE_SAVE_PICTURES_TO_GALLERY, SAVE_PICTURES_TO_GALLERY);
      this.colorMode = await storage.get(STORAGE_COLOR_MODE, COLOR_MODE_DEFAULT);
      this.reopenAtLaunch = await lastPage.shouldRestore();

      if (this.me !== null) {
        return;
      }

      const token = await storage.get(STORAGE_TOKEN);
      if (token !== null) {
        const me = await userApi.me();
        this.me = me;

        if (me !== null) {
          this.loggedIn(me);
        } else {
          this.logout();

        }
      }
    },
    joinChannel() {
      const projectsStore = useProjectsStore();

      if (this.socket === null) {
        this.socket = new Socket(environment.socketUrl, {params: {token: this.token}});
        this.socket.connect();
        this.socket.onError(() => {
          // this.socket.disconnect();
          this.channel = null;
          // this.socket = null;
        });
      }

      return nextTick(() => {
        setTimeout(() => {
          if (this.user === null || this.socket === null) {
            return;
          }

          this.channel = this.socket.channel(`user:${this.user.id}`);

          this.channel.on('project', (data: any) => {
            projectsStore.refreshProject(data.id);
          });

          this.channel.join();
             /* .receive('ok', ({messages}) => console.log('catching up', messages))
              .receive('error', () => {
              })
              .receive('timeout', () => {
              }); */
        }, 50);
      });
    },
    async hasLogged(user: any, token: string, toUrl: string|null = null) {
      this.logout();
      const validate = await UserApi.validate(token);

      if (validate === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      this.loggedIn(validate);

      displayToast('You have successfully signed in.');

      if (toUrl !== null) {
        (this as any).$router.push(toUrl);
      } else {
        (this as any).$router.push({ name: 'home' });
      }
    },
    logout() {
      const projectsStore = useProjectsStore();
      projectsStore.$reset();

      this.logged = false;
      this.user = null;
      this.product = null;
      this.plan = null;
      this.features = null;
      this.token = null;

      storage.remove(STORAGE_TOKEN);

      /*
      if (redirect) {
        (this as any).$router.push({ name: 'login' });
      }*/
    },
    loggedIn(data: any) {
      this.logged = true;
      this.user = data.user;
      this.token = data.jwt;

      if (data.product) {
        this.product = data.product;
      }

      if (data.plan) {
        this.plan = data.plan;
      }

      if (data.features) {
        this.features = data.features;
      }

      storage.save(STORAGE_TOKEN, this.token);

      const projectsStore = useProjectsStore();
      projectsStore.initProjects();

      this.joinChannel();
    },
    async setUiMode(value: string) {
      await storage.save(STORAGE_UI_MODE, value);
      location.reload();
    },
    setReopenAtLaunch(value: boolean) {
      lastPage.enableRestore(value);
      this.reopenAtLaunch = value;
    },
    setDisplayBreadcrumbs(value: boolean) {
      storage.save(STORAGE_DISPLAY_BREADCRUMBS, value);
    },
    setDisplayVersionToolbar(value: boolean) {
      storage.save(STORAGE_DISPLAY_VERSION, value);
    },
    setSavePicturesToGallery(value: boolean) {
      storage.save(STORAGE_SAVE_PICTURES_TO_GALLERY, value);
    },
    setColorMode(value: string) {
      storage.save(STORAGE_COLOR_MODE, value);
      displayToast(`Color theme preference "${value}" saved.`);
      this.colorMode = value;

      if (value === 'auto') {
        document.documentElement.classList.remove('light');
        document.documentElement.classList.remove('dark');
      } else if (value === 'light') {
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
      } else {
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
      }
    }
  }
})
