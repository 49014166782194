// export const UI_MODE_DEFAULT = 'md';
export const UI_MODE_DEFAULT = 'ios';

export const STORAGE_TOKEN = 'token';
export const STORAGE_UI_MODE = 'ui_mode';
export const STORAGE_COLOR_MODE = 'color_mode';
export const STORAGE_DISPLAY_VERSION = 'version_preference';
export const STORAGE_DISPLAY_BREADCRUMBS = 'breadcrumbs_preference';
export const STORAGE_SAVE_PICTURES_TO_GALLERY = 'save_gallery';
export const STORAGE_OPEN_SCREEN_PREFERENCE = 'open_screen_preference';
export const STORAGE_LAST_SCREEN = 'last_screen';

export const COLOR_MODE_DEFAULT = 'auto';
export const DISPLAY_VERSION_DEFAULT = false;
export const DISPLAY_BREADCRUMBS_DEFAULT = true;
export const SAVE_PICTURES_TO_GALLERY = true;
export const LAST_SCREEN_DEFAULT = false;

export const TOAST_DURATION = 2500;
export const TOAST_SUCCESS = 'success';
export const TOAST_ERROR = 'danger';

export const PROJECTS_SORT_KEY = 'projects_sort';
export const TRACKS_SORT_KEY = 'tracks_sort';
export const PICTURES_SORT_KEY = 'pictures_sort';

export const PROJECTS_DEFAULT_SORT = 'last_active_at_desc';
export const PROJECTS_PER_PAGE = 20;

export const TRACKS_DEFAULT_SORT = 'last_active_at_desc';
export const PICTURES_DEFAULT_SORT = 'position_asc';

export const PROJECTS_VIEW_KEY = 'projects_view';
export const TRACKS_VIEW_KEY = 'tracks_view';
export const PICTURES_VIEW_KEY = 'pictures_view';

export const PROJECTS_DEFAULT_VIEW = 'list';
export const TRACKS_DEFAULT_VIEW = 'list';
export const PICTURES_DEFAULT_VIEW = 'card';
