import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!

  return (_openBlock(), _createBlock(_component_ion_toolbar, { class: "toolbar-color-mode" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_segment, {
        onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.uiModeChanged($event))),
        value: _ctx.uiMode
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_segment_button, { value: "md" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.logoAndroid }, null, 8, ["icon"]),
                  _createTextVNode(" Android")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_segment_button, { value: "ios" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.logoApple }, null, 8, ["icon"]),
                  _createTextVNode(" iOS")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    _: 1
  }))
}