<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Account</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <pull-to-refresh></pull-to-refresh>

      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-list>
              <ion-list-header class="ion-padding-bottom">
                <ion-label>
                  <ion-icon :icon="personCircleOutline"></ion-icon> Infos</ion-label>
              </ion-list-header>
              <ion-item lines="none">
                <ion-label>
                  <strong>Email:</strong> {{ user.email }}
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label>
                  <strong>Created:</strong> {{ new Date(user.inserted_at).toLocaleDateString() }}
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label class="ion-text-center">
                  To manage your user account<br>please use the <a target="_blank" href="https://www.gearpix.app">GearPix website</a>
                </ion-label>
              </ion-item>
            </ion-list>

            <ion-list>
              <ion-list-header class="ion-padding-bottom">
                <ion-label><ion-icon :icon="cashOutline"></ion-icon> Subscription</ion-label>
              </ion-list-header>
              <ion-item lines="none">
                <ion-label>
                  <strong>Plan:</strong>&nbsp;
                  <span v-if="product">{{ product.name }}</span>
                  <span v-if="!product">n/a</span>
                </ion-label>
              </ion-item>
              <ion-item lines="none" v-if="plan">
                <ion-label>
                  <strong>Active since:</strong>&nbsp;{{ new Date(plan.active_since).toLocaleDateString() }}
                </ion-label>
              </ion-item>
              <ion-item lines="none" v-if="plan">
                <ion-label>
                  <strong>Active until:</strong>&nbsp;
                  <span v-if="plan && plan.active_until">{{ new Date(plan.active_until).toLocaleDateString() }}</span>
                  <span v-if="!plan || !plan.active_until">-</span>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label class="ion-text-center">
                  To manage your subscription<br>please use the <a target="_blank" href="https://www.gearpix.app">GearPix website</a>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';

import { useUserStore } from '@/stores/UserStore';

import PullToRefresh from '@/components/PullToRefresh.vue';

import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonButtons,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/vue';
import { personCircleOutline, cashOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'AccountPage',
  components: {
    PullToRefresh,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonListHeader,
    IonItem
  },
  setup() {
    const router = useIonRouter();
    const userStore = useUserStore();
    const { user, product, plan } = storeToRefs(userStore);

    return {
      userStore,
      user,
      product,
      plan,
      router,
      personCircleOutline,
      cashOutline
    };
  },
  computed: {

  },
  methods: {

  }
});
</script>
