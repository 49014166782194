import { CapacitorHttp } from '@capacitor/core';
import type { HttpResponse } from '@capacitor/core';

import { environment } from '@/environments/environment';

import httpUtils from '../utils/http';

const validate = async (token: string): Promise<any> => {
  try {
    const options = {
      url: `${environment.baseUrl}/login/validate`,
      headers: { 'Content-Type': 'application/json' },
      data: { firebase_token: token },
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status !== 200) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

const me = async (): Promise<any> => {
  try {
    const options = await httpUtils.getOptions('me');
    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status !== 200) {
      return null;
    }

    if (!response.data) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

const validateQrToken = async (token: string): Promise<any> => {
  try {
    const options = await httpUtils.getOptions('qrtoken/validate');
    options.data = {token: token};

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status !== 200) {
      return null;
    }

    if (!response.data) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

/* eslint-disable no-undef */
export default {
  validateQrToken,
  validate,
  me
};
