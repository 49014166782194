<template>
  <ion-toolbar>
    <ion-breadcrumbs :max-items="3" :items-before-collapse="1" :items-after-collapse="2"
      @ionCollapsedClick.stop="presentPopover($event)">
      <ion-breadcrumb :router-link="`/home`"><ion-icon slot="start" :icon="home"></ion-icon></ion-breadcrumb>
      <ion-breadcrumb @click="goTo(`/project/${project.id}`)" :data-url="`/project/${project.id}`">
        <div class="bc-truncable">{{ project.title }}</div>
      </ion-breadcrumb>
      <ion-breadcrumb v-if="version" :router-link="`/project/${project.id}/${version.id}`">
        <div class="bc-truncable">{{ version.title }}</div>
      </ion-breadcrumb>
      <ion-breadcrumb v-if="version && track" :router-link="`/project/${project.id}/${version.id}/track/${track.id}`">
        <div class="bc-truncable">{{ track.title }}</div>
      </ion-breadcrumb>
    </ion-breadcrumbs>
    <ion-popover :is-open="popoverOpen" :event="event" @didDismiss="popoverOpen = false" size="auto">
      <ion-content>
        <ion-list>
          <ion-item
              v-for="(breadcrumb, i) in collapsedBreadcrumbs"
              :key="i"
              :router-link="`${breadcrumb.dataset.url}`"
              :lines="i === collapsedBreadcrumbs.length - 1 ? 'none' : undefined"
          >
            <ion-label><span v-html="breadcrumb.innerHTML"></span></ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-popover>
  </ion-toolbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';

import { useProjectsStore } from '@/stores/ProjectsStore';

import {
  IonToolbar,
  IonIcon,
  IonBreadcrumbs,
  IonBreadcrumb,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPopover, useIonRouter
} from '@ionic/vue';
import { home } from 'ionicons/icons';

// todo a bit static on the props, maybe revisit
export default defineComponent({
  name: 'BreadcrumbsToolbar',
  components: {
    IonToolbar,
    IonIcon,
    IonBreadcrumbs,
    IonBreadcrumb,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPopover
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    versionId: {
      type: String,
      required: true
    },
    trackId: {
      type: String,
      required: false
    }
  },
  /* eslint-disable indent */
  data(): {
    popoverOpen: boolean,
    collapsedBreadcrumbs: any,
    event: CustomEvent|null,
  } {
    return {
      popoverOpen: false,
      collapsedBreadcrumbs: [],
      event: null,
    }
  },
  setup() {
    const router = useIonRouter();
    const projectsStore = useProjectsStore();
    const { projects } = storeToRefs(projectsStore);

    return {
      router,
      projects,
      home
    };
  },
  computed: {
    project() {
      if (!this.projects[this.projectId]) {
        return null;
      }

      return this.projects[this.projectId];
    },
    version() {
      if (!this.project || !this.projects[this.projectId].versions[this.versionId]) {
        return null;
      }

      return this.projects[this.projectId].versions[this.versionId];
    },
    track() {
      if (!this.trackId || !this.project || !this.version
      || !this.projects[this.projectId].versions[this.versionId].tracks[this.trackId]) {
        return null;
      }

      return this.projects[this.projectId].versions[this.versionId].tracks[this.trackId];
    },
  },
  methods: {
    presentPopover(event: CustomEvent) {
      this.collapsedBreadcrumbs = event.detail.collapsedBreadcrumbs;
      this.event = event;
      this.popoverOpen = true;
    },
    goTo(url: string) {
      if (this.popoverOpen) {
        return;
      }

      this.router.push(url);
    }
  }
});
</script>

<style scoped>
  ion-toolbar {
    --min-height: 36px;
  }

  ion-breadcrumbs {
    flex-wrap: nowrap;
  }

  ion-icon {
    margin-inline-end: 0;
  }

  ion-breadcrumb {
    flex-basis: fit-content;
    flex-grow: 1;
    flex-shrink: 1;

    text-align: center;

    white-space: nowrap;
    overflow: hidden;
  }

  /* home icon */
  ion-breadcrumb:first-child {
    max-width: 43px;
  }

  ion-breadcrumb::part(native) {
    padding-inline-start: 6px !important;
    padding-inline-end: 6px !important;

    white-space: nowrap;
    overflow: hidden;

    flex-basis: fit-content;
    flex-grow: 1;
    flex-shrink: 1;

    //max-width: 150px;
  }

  .bc-truncable {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    width: 100%;
  }

  ion-breadcrumb::part(separator) {
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
</style>
