import { nextTick } from 'vue';
import { defineStore } from 'pinia';

import {
  TOAST_ERROR,
  PROJECTS_DEFAULT_SORT,
  PROJECTS_PER_PAGE,
  PROJECTS_SORT_KEY
} from '@/config/config';

import storage from '@/utils/storage';
import ProjectApi from '../api/ProjectApi';
import { displayToast } from '@/utils/toast';

import type { Project } from '@/types/project';
import type { Version } from '@/types/version';
import type { Track } from '@/types/track';
import type { Picture } from '@/types/picture';

interface State {
  loading: boolean,
  creating: boolean,
  projects: Record<string, Project>,
  total: number,
  perPage: number,
  sortBy: string,
  search: string|null
  currentPage: number,
  deletedProjects: Record<string, Project>
  deletedVersions: Record<string,Version>
  deletedTracks: Record<string, Track>
  deletedPictures: Record<string, Picture>
}

export const useProjectsStore = defineStore('projects', {
  state: (): State => (
    {
      loading: false,
      creating: false,
      projects: {},
      total: 0,
      perPage: PROJECTS_PER_PAGE,
      sortBy: PROJECTS_DEFAULT_SORT,
      search: null,
      currentPage: 1,
      deletedProjects: {},
      deletedVersions: {},
      deletedTracks: {},
      deletedPictures: {}
    }
  ),
  actions: {
    async initProjects() {
      this.loading = true;

      this.sortBy = await storage.get(PROJECTS_SORT_KEY, PROJECTS_DEFAULT_SORT)
      const data = await ProjectApi.getProjects({sort: this.sortBy, per_page: this.perPage.toString()});

      this.loading = false;

      if (!data || data.projects === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      // @ts-ignore
      this.projects = data.projects;
      this.total = data.total;
      this.perPage = data.per_page;
      this.currentPage = data.page;
    },
    async getDeleted() {
      this.getDeletedProjects();
      // this.getDeletedVersions();
      // this.getDeletedTracks();
      this.getDeletedPictures();
    },

    async getDeletedProjects() {
      this.loading = true;
      const projects = await ProjectApi.getDeletedProjects()
      this.loading = false;

      if (projects === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      // @ts-ignore
      this.deletedProjects = projects;
    },
    async getDeletedVersions() {
      this.loading = true;
      const versions = await ProjectApi.getVersions({'deleted': '1'});
      this.loading = false;

      if (versions === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      // @ts-ignore
      this.deletedVersions = versions;
    },
    async getDeletedTracks() {
      this.loading = true;
      const tracks = await ProjectApi.getTracks({'deleted': '1'});
      this.loading = false;

      if (tracks === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      // @ts-ignore
      this.deletedTracks = tracks;
    },
    async getDeletedPictures() {
      this.loading = true;
      const pictures = await ProjectApi.getPictures({'deleted': '1'});
      this.loading = false;

      if (pictures === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      // @ts-ignore
      this.deletedPictures = pictures;
    },

    // ---------- PROJECTS ----------

    async createProject(title: string): Promise<string|undefined> {
      this.creating = true;
      const project = await ProjectApi.createProject({ title });
      this.creating = false;

      if (!project) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      this.projects[project.id] = project;

      displayToast('The project has been created.');
      return project.id;
    },
    async editProject(project: Project, title: string) {
      this.loading = true;
      const projectEdited = await ProjectApi.updateProject(project, { title });
      this.loading = false;

      if (projectEdited === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      this.updateLastActive(projectEdited.id);
      this.projects[projectEdited.id] = projectEdited;

      displayToast('The project has been updated.');
    },
    async deleteProject(project: Project, gotoUrl?: string) {
      this.loading = true;
      const projectDeleted = await ProjectApi.deleteProject(project);
      this.loading = false;

      if (projectDeleted !== true) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      delete this.projects[project.id];

      displayToast('You project has been deleted.');

      if (gotoUrl) {
        (this as any).$router.push(gotoUrl);
      }
    },
    updateLastActive(projectId: string) {
      if (this.projects[projectId] === undefined) {
        return;
      }

      const now = new Date();
      const projectToUpdate = this.projects[projectId];
      projectToUpdate.last_active_at = now.toISOString();

      this.projects[projectId] = projectToUpdate;
    },
    async restoreDeletedProject(projectId: string) {
      const projectRestored = await ProjectApi.restoreProject(projectId);

      if (projectRestored === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      delete this.deletedProjects[projectRestored.id];
      this.refreshProjects();

      displayToast('The project has been restored.');
    },
    async setSort(sortBy: string) {
      storage.save(PROJECTS_SORT_KEY, sortBy);

      this.sortBy = sortBy;
      this.currentPage = 1;
      this.refreshProjects();
    },
    async setSearch(text: string|null) {
      this.search = text;
      this.currentPage = 1;
      this.refreshProjects();
    },
    async refreshProject(projectId: string) {
      const project = await ProjectApi.getProject(projectId);

      if ((project === null && this.projects[projectId])
      || (project && project.deleted_at !== null && this.projects[projectId])) {
        delete this.projects[projectId];
        return;
      }

      if (project) {
        this.projects[projectId] = project;
      }

      return;
    },
    async refreshProjects() {
      this.loading = true;

      let data = null
      for (let i = 0; i < this.currentPage; i++) {
        data = await ProjectApi.getProjects({sort: this.sortBy, per_page: this.perPage.toString(), text: this.search});

        if (!data || data.projects === null) {
          // displayToast('An error occurred. Please try again.', TOAST_ERROR);
          return;
        }

        // @ts-ignore
        this.projects = {};
        await nextTick();
        this.projects = data.projects;
      }

      this.loading = false;

      if (data) {
        this.total = data.total;
        this.perPage = data.per_page;
        this.currentPage = data.page;
      }
    },
    async getNextPage() {
      if (Math.ceil(this.total / this.perPage) <= this.currentPage) {
        return;
      }

      this.loading = true;

      const data = await ProjectApi.getProjects({sort: this.sortBy, page: (this.currentPage + 1), per_page: this.perPage.toString(), text: this.search});

      this.loading = false;

      if (!data || data.projects === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      // @ts-ignore
      this.projects = { ...this.projects, ...data.projects } ;
      this.total = data.total;
      this.perPage = data.per_page;
      this.currentPage = data.page;
    },

    // ---------- VERSIONS ----------

    async createVersion(projectId: string, title: string, cloneVersionId: string|null = null): Promise<string|undefined> {
      if (!this.projects[projectId]) {
        return;
      }

      this.creating = true;
      const version = await ProjectApi.createVersion(projectId,{ title }, cloneVersionId);
      this.creating = false;

      if (version === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      this.updateLastActive(projectId);
      this.projects[projectId].versions[version.id] = version;
      displayToast('The version has been created.');
      return version.id;
    },
    async editVersion(projectId: string, version: Version, title: string) {
      if (!this.projects[projectId] || !this.projects[projectId].versions[version.id]) {
        return;
      }

      const versionEdited = await ProjectApi.updateVersion(version.id, { title });

      if (versionEdited === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      this.updateLastActive(projectId);
      this.projects[projectId].versions[version.id] = versionEdited;
      displayToast('The version has been updated.');
    },
    async deleteVersion(projectId:string, version: Version, gotoUrl?: string) {
      if (!this.projects[projectId] || !this.projects[projectId].versions[version.id]) {
        return;
      }

      this.loading = true;
      const versionDeleted = await ProjectApi.deleteVersion(version);
      this.loading = false;

      if (versionDeleted !== true) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      delete this.projects[projectId].versions[version.id];
      displayToast('The version has been deleted.');
      if (gotoUrl) {
        (this as any).$router.push(gotoUrl);
      }
    },
    async restoreDeletedVersion(versionId: string) {
      const versionRestored = await ProjectApi.restoreVersion(versionId);

      if (versionRestored === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      delete this.deletedTracks[versionRestored.id];
      this.initProjects();
      displayToast('The version has been restored.');
    },

    // ---------- TRACKS ----------

    async createTrack(projectId: string, versionId: string, title: string): Promise<string|undefined> {
      if (!this.projects[projectId] || !this.projects[projectId].versions[versionId]) {
        return;
      }

      this.creating = true;
      const track = await ProjectApi.createTrack(versionId,{ title });
      this.creating = false;

      if (track === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      this.updateLastActive(projectId);
      this.projects[projectId].versions[versionId].tracks[track.id] = track;
      displayToast('You track has been created.');
      return track.id;
    },
    async editTrack(projectId: string, versionId: string, track: Track, title: string) {
      if (!this.projects[projectId] || !this.projects[projectId].versions[versionId]) {
        return;
      }

      const trackEdited = await ProjectApi.updateTrack(versionId, track.id, { title });

      if (trackEdited === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      this.updateLastActive(projectId);
      this.projects[projectId].versions[versionId].tracks[trackEdited.id] = trackEdited;
      displayToast('The track has been updated.');
    },
    async deleteTrack(projectId:string, versionId: string, track: Track, gotoUrl?: string) {
      if (!this.projects[projectId] || !this.projects[projectId].versions[versionId]) {
        return;
      }

      this.loading = true;
      const trackDeleted = await ProjectApi.deleteTrack(track);
      this.loading = false;

      if (trackDeleted !== true) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      delete this.projects[projectId].versions[versionId].tracks[track.id];
      displayToast('The track has been deleted.');
      if (gotoUrl) {
        (this as any).$router.push(gotoUrl);
      }
    },
    async restoreDeletedTrack(trackId: string) {
      const trackRestored = await ProjectApi.restoreTrack(trackId);

      if (trackRestored === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      // this.updateLastActive(trackRestored.id);
      delete this.deletedTracks[trackRestored.id];
      this.initProjects();
      displayToast('The track has been restored.');
    },

    // ---------- PICTURES ----------

    async createPicture(projectId:string, versionId: string, trackId: string, pictureData: any, replacePictureId: string|null = null): Promise<string|undefined> {
      if (!this.projects[projectId] || !this.projects[projectId].versions[versionId]
          || !this.projects[projectId].versions[versionId].tracks[trackId]) {
        return;
      }

      this.creating = true;
      const picture = await ProjectApi.createPicture(trackId , pictureData, replacePictureId);
      this.creating = false;

      if (picture === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      this.updateLastActive(projectId);

      if (replacePictureId) {
        delete this.projects[projectId].versions[versionId].tracks[trackId].items[replacePictureId];
      }

      this.projects[projectId].versions[versionId].tracks[trackId].items[picture.id] = picture;

      displayToast('The picture has been uploaded.');
      return picture.id;
    },
    async editPicture(projectId: string, versionId: string, trackId: string, picture: Picture, description: string) {
      if (!this.projects[projectId] || !this.projects[projectId].versions[versionId] || !this.projects[projectId].versions[versionId].tracks[trackId]
        || !this.projects[projectId].versions[versionId].tracks[trackId].items[picture.id]) {
        return;
      }

      const pictureUpdated = await ProjectApi.updatePicture(
        projectId,
        trackId,
        picture.id,
        { description }
      );

      if (pictureUpdated === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      this.updateLastActive(projectId);
      this.projects[projectId].versions[versionId].tracks[trackId].items[pictureUpdated.id] = pictureUpdated;
      displayToast('The picture has been updated.');
    },
    async repositionPicture(projectId: string, versionId: string, trackId: string, pictureId: string, newPosition: number) {
      if (!this.projects[projectId] || !this.projects[projectId].versions[versionId] || !this.projects[projectId].versions[versionId].tracks[trackId]
          || !this.projects[projectId].versions[versionId].tracks[trackId].items[pictureId]) {
        return;
      }

      const pictureUpdated = await ProjectApi.repositionPicture(pictureId, newPosition);

      if (pictureUpdated === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      this.updateLastActive(projectId);
      // this.projects[projectId].tracks[trackId].items[pictureUpdated.id] = pictureUpdated;
      setTimeout(() => {
        this.refreshProjects();
      }, 500);
    },
    async deletePicture(projectId:string, versionId: string, trackId: string, picture: Picture, gotoUrl?: string) {
      if (!this.projects[projectId] || !this.projects[projectId].versions[versionId]
        || !this.projects[projectId].versions[versionId].tracks[trackId]) {
        return;
      }

      this.loading = true;
      const pictureDeleted = await ProjectApi.deletePicture(picture);
      this.loading = false;

      if (pictureDeleted !== true) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      delete this.projects[projectId].versions[versionId].tracks[trackId].items[picture.id];
      displayToast('The picture has been deleted.');
      if (gotoUrl) {
        (this as any).$router.push(gotoUrl);
      }
    },
    async restoreDeletedPicture(pictureId: string) {
      const pictureRestored = await ProjectApi.restorePicture(pictureId);

      if (pictureRestored === null) {
        displayToast('An error occurred. Please try again.', TOAST_ERROR);
        return;
      }

      // this.updateLastActive(pictureRestored.id);
      delete this.deletedPictures[pictureRestored.id];
      this.initProjects();
      displayToast('The picture has been restored.');
    },
  }
})
