import { CapacitorHttp } from '@capacitor/core';
import type { HttpResponse } from '@capacitor/core';

import type { GetProjectsResponse } from '@/types/api/projects';
import type { Project } from '@/types/project';
import type { Version } from '@/types/version';
import type { Track} from '@/types/track';
import type { Picture } from '@/types/picture';

import httpUtils from '../utils/http';
import ProjectUtils from '@/utils/ProjectUtils';

// Note int parameters are to be given as string because otherwise iOS is not happy (?)

// ---------- PROJECTS ----------

const getProject = async (projectId: string): Promise<Project|null> => {
  try {
    const options = await httpUtils.getOptions(`projects/${projectId}`);

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status !== 200) {
      return null;
    }

    if (!response.data && !response.data.id) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

const getProjects = async (params: any|null = null): Promise<GetProjectsResponse|null> => {
  try {
    const options = await httpUtils.getOptions('projects');
    if (params !== null) {
      options.params = ProjectUtils.cleanNullAttributes(params);
    }

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status !== 200) {
      return null;
    }

    if (!response.data && !response.data.projects) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

const getDeletedProjects = async (): Promise<Record<string, Project>|null> => {
  try {
    const options = await httpUtils.getOptions('projects');
      options.params = {deleted: '1'};

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status !== 200) {
      return null;
    }

    if (!response.data && !response.data.projects) {
      return null;
    }

    return response.data.projects;
  } catch (error) {
    return null;
  }
}

const createProject = async (projectData: any): Promise<Project|null> => {
  try {
    const options = await httpUtils.getOptions('projects');
    options.data = { project: projectData };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.id) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

const updateProject = async (project: Project, projectData: any): Promise<Project|null> => {
  try {
    const options = await httpUtils.getOptions(`projects/${project.id}`);
    options.data = { project: projectData };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.id) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

const deleteProject = async (project: Project): Promise<boolean|null> => {
  const options = await httpUtils.getOptions(`projects/${project.id}`);
  const response: HttpResponse = await CapacitorHttp.delete(options);

  if (response.status !== 201) {
    return null;
  }

  if (!response.data && !response.data.project) {
    return null;
  }

  return response.data.project.deleted;
}

const restoreProject = async (projectId: string): Promise<Project|null> => {
  try {
    const options = await httpUtils.getOptions(`projects/${projectId}`);
    options.params = { restore: '1' };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.id) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

// ---------- VERSIONS ----------

const getVersions = async (params: any|null = null): Promise<Array<Version>|null> => {
  try {
    const options = await httpUtils.getOptions('versions');
    if (params !== null) {
      options.params = params;
    }

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status !== 200) {
      return null;
    }

    if (!response.data && !response.data.versions) {
      return null;
    }

    return response.data.versions;
  } catch (error) {
    return null;
  }
}

const createVersion = async (projectId: string, versionData: any, cloneVersionId: string|null = null): Promise<Version|null> => {
  try {
    const options = await httpUtils.getOptions('versions');
    options.data = {project_id: projectId, version: versionData };

    if (cloneVersionId) {
      options.data.clone = cloneVersionId;
    }

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.id) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

const updateVersion = async (versionId: string, versionData: any): Promise<Version|null> => {
  try {
    const options = await httpUtils.getOptions(`versions/${versionId}`);
    options.data = {version: versionData };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.id) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

const deleteVersion = async (version: Version): Promise<boolean|null> => {
  try {
    const options = await httpUtils.getOptions(`versions/${version.id}`);

    const response: HttpResponse = await CapacitorHttp.delete(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.version) {
      return null;
    }

    return response.data.version.deleted;
  } catch (error) {
    return null;
  }
}

const restoreVersion = async (versionId: string): Promise<Version|null> => {
  try {
    const options = await httpUtils.getOptions(`versions/${versionId}`);
    options.params = { restore: '1' };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.id) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

// ---------- TRACKS ----------

const getTracks = async (params: any|null = null): Promise<Array<Track>|null> => {
  try {
    const options = await httpUtils.getOptions('tracks');
    if (params !== null) {
      options.params = params;
    }

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status !== 200) {
      return null;
    }

    if (!response.data && !response.data.tracks) {
      return null;
    }

    return response.data.tracks;
  } catch (error) {
    return null;
  }
}

const createTrack = async (versionId: string, trackData: any): Promise<Track|null> => {
  try {
    const options = await httpUtils.getOptions('tracks');
    options.data = {version_id: versionId, track: trackData };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.id) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

const updateTrack = async (versionId: string, trackId: string, trackData: any): Promise<Track|null> => {
  try {
    const options = await httpUtils.getOptions(`tracks/${trackId}`);
    options.data = {version_id: versionId, track_id: trackId, track: trackData };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.id) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

const deleteTrack = async (track: Track): Promise<boolean|null> => {
  try {
    const options = await httpUtils.getOptions(`tracks/${track.id}`);

    const response: HttpResponse = await CapacitorHttp.delete(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.track) {
      return null;
    }

    return response.data.track.deleted;
  } catch (error) {
    return null;
  }
}

const restoreTrack = async (trackId: string): Promise<Track|null> => {
  try {
    const options = await httpUtils.getOptions(`tracks/${trackId}`);
    options.params = { restore: '1' };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.id) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

// ---------- PICTURES ----------

const getPictures = async (params: any|null = null): Promise<Array<Picture>|null> => {
  try {
    const options = await httpUtils.getOptions('pictures');
    if (params !== null) {
      options.params = params;
    }

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status !== 200) {
      return null;
    }

    if (!response.data && !response.data.pictures) {
      return null;
    }

    return response.data.pictures;
  } catch (error) {
    return null;
  }
}

const createPicture = async (trackId: string, pictureData: any, replacePictureId: string|null = null): Promise<Picture|null> => {
  try {
    const options = await httpUtils.getOptions('pictures');

    delete pictureData.data.exif;
    delete pictureData.data.saved;

    // Request would not be made in iOS if object was passed.
    const pictureDataString = JSON.stringify(pictureData);

    options.data = {track_id: trackId, picture: pictureDataString };

    if (replacePictureId) {
      options.data.replace_picture_id = replacePictureId;
    }

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.id) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

const deletePicture = async (picture: Picture): Promise<boolean|null> => {
  try {
    const options = await httpUtils.getOptions(`pictures/${picture.id}`);

    const response: HttpResponse = await CapacitorHttp.delete(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.picture) {
      return null;
    }

    return response.data.picture.deleted;
  } catch (error) {
    return null;
  }
}

const updatePicture = async (projectId: string, trackId: string, pictureId: string, pictureData: any): Promise<Picture|null> => {
  try {
    const options = await httpUtils.getOptions(`pictures/${pictureId}`);
    options.data = {project_id: projectId, track_id: trackId, picture: pictureData };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.id) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

const repositionPicture = async (pictureId: string, position: number): Promise<Picture|null> => {
  try {
    const options = await httpUtils.getOptions(`pictures/${pictureId}`);
    options.params = { position: position };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.id) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

const restorePicture = async (pictureId: string): Promise<Picture|null> => {
  try {
    const options = await httpUtils.getOptions(`pictures/${pictureId}`);
    options.params = { restore: '1' };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status !== 201) {
      return null;
    }

    if (!response.data && !response.data.id) {
      return null;
    }

    return response.data;
  } catch (error) {
    return null;
  }
}

/* eslint-disable no-undef */
export default {
  createProject,
  updateProject,
  deleteProject,
  restoreProject,
  createVersion,
  updateVersion,
  deleteVersion,
  restoreVersion,
  createTrack,
  updateTrack,
  deleteTrack,
  restoreTrack,
  createPicture,
  updatePicture,
  repositionPicture,
  restorePicture,
  deletePicture,
  getProject,
  getProjects,
  getVersions,
  getDeletedProjects,
  getTracks,
  getPictures
};
