<template>
  <ion-toolbar class="toolbar-color-mode">
    <ion-segment @ionChange="uiModeChanged($event)" :value="uiMode">
      <ion-segment-button value="md">
        <ion-label><ion-icon :icon="logoAndroid"></ion-icon> Android</ion-label>
      </ion-segment-button>
      <ion-segment-button value="ios">
        <ion-label><ion-icon :icon="logoApple"></ion-icon> iOS</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';

import { useUserStore } from '@/stores/UserStore';

import {
  IonToolbar,
  IonLabel,
  IonIcon,
  IonSegment,
  IonSegmentButton
} from '@ionic/vue';
import { logoAndroid, logoApple } from 'ionicons/icons';

export default defineComponent({
  name: 'UiModeToggle',
  components: {
    IonToolbar,
    IonLabel,
    IonIcon,
    IonSegment,
    IonSegmentButton
  },
  setup() {
    const userStore = useUserStore();
    const { uiMode } = storeToRefs(userStore);

    return {
      userStore,
      uiMode,
      logoAndroid,
      logoApple
    };
  },
  methods: {
    uiModeChanged(event: any) {
      this.userStore.setUiMode(event.detail.value);
    },
  }
});
</script>

<style scoped>
  ion-toolbar.toolbar-color-mode {
    --background: transparent;
  }
</style>
