import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import lastPage from '@/utils/lastPage';

import { useUserStore } from '@/stores/UserStore';

import LoginPage from '../views/LoginPage.vue';
import SignUpPage from '../views/SignUpPage.vue';
import ForgottenPasswordPage from '../views/ForgottenPasswordPage.vue';
import AuthPluginPage from '../views/AuthPluginPage.vue';
import AccountPage from '../views/AccountPage.vue';
import PreferencesPage from '../views/PreferencesPage.vue';

import HelpAppPage from '../views/HelpAppPage.vue';

import HomePage from '../views/HomePage.vue';
import ProjectPage from '../views/ProjectPage.vue';
import TrackPage from '../views/TrackPage.vue';
import PicturesPage from '../views/PicturesPage.vue';
import RecentlyDeletedPage from '../views/RecentlyDeletedPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    /* eslint-disable @typescript-eslint/no-unused-vars */
    redirect: to => {
      lastPage.shouldRestore().then((value) => {
        if (value) {
          lastPage.getLastPath().then((path) => {
            return {
              path: path
            }
          });
        }
      });

      return {path: '/home'}
    },
  },
  {
    name: 'login',
    path: '/login',
    component: LoginPage,
    meta: { requiresAuth: false }
  },
  {
    name: 'logout',
    path: '/logout',
    component: {},
    meta: { requiresAuth: false }
  },
  {
    name: 'signup',
    path: '/signup',
    component: SignUpPage,
    meta: { requiresAuth: false }
  },
  {
    name: 'forgotten-password',
    path: '/reset-password',
    component: ForgottenPasswordPage,
    meta: { requiresAuth: false }
  },
  {
    name: 'account',
    path: '/account',
    component: AccountPage,
  },
  {
    name: 'preferences',
    path: '/preferences',
    component: PreferencesPage,
    meta: { requiresAuth: false }
  },
  {
    name: 'help-app',
    path: '/help-app',
    component: HelpAppPage,
  },
  {
    name: 'auth-plugin',
    path: '/auth-plugin',
    component: AuthPluginPage,
  },
  {
    name: 'home',
    path: '/home',
    component: HomePage,
    meta: { save: true }
  },
  {
    name: 'project',
    path: '/project/:id',
    component: ProjectPage,
    meta: { save: true }
  },
  {
    name: 'project_version',
    path: '/project/:id/:versionId',
    component: ProjectPage,
    meta: { save: true }
  },
  {
    name: 'track',
    path: '/project/:projectId/:versionId/track/:trackId',
    component: TrackPage,
    meta: { save: true }
  },
  {
    name: 'pictures',
    path: '/project/:projectId/:versionId/track/:trackId/pictures/:pictureId?',
    component: PicturesPage,
    meta: { save: true }
  },
  {
    name: 'deleted',
    path: '/deleted',
    component: RecentlyDeletedPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to) => {
  const userStore = useUserStore();

  if (to.name === 'logout') {
    userStore.logout();

    return {
      path: '/login'
    }
  }

  await userStore.init();

  // maybe save last screen
  if (to.meta.save === true) {
    lastPage.save(to.fullPath);
  }

  // assumes requiresAuth is by default
  if ((to.meta.requiresAuth === undefined || (to.meta.requiresAuth && to.meta.requiresAuth !== false)) && !userStore.logged) {
    return {
      path: '/login',
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    }
  }
});
export default router;
