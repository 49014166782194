import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "description ion-margin-bottom"
}
const _hoisted_3 = { class: "content-actions" }
const _hoisted_4 = {
  class: "sortable-handle",
  style: {"padding-top":"4px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_pictures_actions = _resolveComponent("pictures-actions")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_img, {
        "router-link": `/project/${_ctx.projectId}/${_ctx.versionId}/track/${_ctx.trackId}/pictures/${_ctx.picture.id}`,
        src: _ctx.picture.url.thumbnail,
        alt: ""
      }, null, 8, ["router-link", "src"]),
      _createVNode(_component_ion_card_header, {
        "router-link": `/project/${_ctx.projectId}/${_ctx.versionId}/track/${_ctx.trackId}/pictures/${_ctx.picture.id}`
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(new Date(_ctx.picture.inserted_at).toLocaleString()), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["router-link"]),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              (_ctx.picture.description)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.picture.description), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.menuOutline,
                  size: "large"
                }, null, 8, ["icon"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.openReplaceModal()), ["prevent"])),
                  fill: "clear",
                  slot: "end",
                  size: "default"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.cameraReverseOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_pictures_actions, {
                  picture: _ctx.picture,
                  trackId: _ctx.trackId,
                  projectId: _ctx.projectId,
                  versionId: _ctx.versionId
                }, null, 8, ["picture", "trackId", "projectId", "versionId"])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}