<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Help</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>

      <ion-grid>
        <ion-row>
          <ion-col>

          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonButtons,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/vue';

export default defineComponent({
  name: 'HelpAppPage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonGrid,
    IonRow,
    IonCol,
  },
  setup() {
    const router = useIonRouter();

    return {
      router,
    };
  },
  computed: {

  },
  methods: {

  }
});
</script>
