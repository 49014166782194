import {
  COLOR_MODE_DEFAULT,
  STORAGE_COLOR_MODE
} from '@/config/config';

import storage from '@/utils/storage';

const applyColorMode = async () => {
  const colorModePreference = await storage.get(STORAGE_COLOR_MODE, COLOR_MODE_DEFAULT);

  if (colorModePreference !== 'auto') {
    document.documentElement.classList.add(colorModePreference);
  }
};

export default {
  applyColorMode
};
