import { Preferences } from '@capacitor/preferences';

const save = async (key: string, data: any): Promise<void> => {
  return await Preferences.set({
    key: key,
    value: data.toString(),
  });
}

const saveJson = (key: string, data: any): void => {
  save(key, JSON.stringify(data));
}

const get = async (key: string, defaultValue: any = null): Promise<any> => {
  const { value } = await Preferences.get({ key: key });

  if (value === null) {
    return defaultValue;
  }

  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

const getJson = async (key: string): Promise<any> => {
  const value = await get(key);
  return JSON.parse(value);
};

const remove = async (key: string): Promise<void> => {
  await Preferences.remove({ key: key });
}

export default {
  save,
  saveJson,
  get,
  getJson,
  remove
};
