<template>
  <ion-menu content-id="main-content">
    <ion-header>
      <ion-toolbar>
        <ion-title>GearPix<!-- {{ logged }} --></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" v-if="user">
      <ion-menu-toggle>
        <ion-list>
          <ion-list-header>
            <ion-label>User</ion-label>
          </ion-list-header>
          <ion-item v-if="logged" lines="none" router-link="/account">
            <ion-icon :icon="personCircleOutline"></ion-icon>
            <ion-label>
              &nbsp;{{ user.email }}
            </ion-label>
          </ion-item>
          <ion-item lines="none" router-link="/preferences">
            <ion-icon :icon="cogOutline"></ion-icon>
            <ion-label>
              &nbsp;Preferences
            </ion-label>
          </ion-item>
          <ion-item v-if="logged" lines="none" router-link="/auth-plugin">
            <ion-icon :icon="qrCodeOutline"></ion-icon>
            <ion-label>
              &nbsp;Authenticate Plugin
            </ion-label>
          </ion-item>
          <ion-item v-if="logged" lines="none" router-link="/logout">
            <ion-icon :icon="logOutOutline"></ion-icon>
            <ion-label>
              &nbsp;Logout
            </ion-label>
          </ion-item>
        </ion-list>

        <ion-list>
          <ion-list-header>
            <ion-label>Projects</ion-label>
          </ion-list-header>
          <ion-item lines="none" router-link="/home">
            <ion-icon :icon="appsOutline"></ion-icon>
            <ion-label>
              &nbsp;My Projects
            </ion-label>
          </ion-item>
          <ion-item lines="none" router-link="/deleted">
            <ion-icon :icon="trashOutline"></ion-icon>
            <ion-label>
              &nbsp;Recently Deleted
            </ion-label>
          </ion-item>
        </ion-list>

        <ion-list v-if="true === false">
          <ion-list-header>
            <ion-label>Help</ion-label>
          </ion-list-header>
          <ion-item lines="none" router-link="/">
            <ion-icon :icon="phonePortraitOutline"></ion-icon>
            <ion-label>
              &nbsp;App
            </ion-label>
          </ion-item>
          <ion-item lines="none" router-link="/">
            <ion-icon :icon="desktopOutline"></ion-icon>
            <ion-label>
              &nbsp;Plugin
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-menu-toggle>
    </ion-content>
    <ion-footer :translucent="true" class="menu-footer ion-no-border">
      <color-mode-toggle></color-mode-toggle>
      <div class="ion-text-center ion-margin-top">
        GearPix ©{{ new Date().getFullYear() }} FunkyBits.fr
      </div>
    </ion-footer>
  </ion-menu>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';

import { useUserStore } from '@/stores/UserStore';

import ColorModeToggle from '@/components/ColorModeToggle.vue';

import {
  IonMenu,
  IonMenuToggle,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonFooter,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonIcon
} from '@ionic/vue';
import { personCircleOutline, logOutOutline, trashOutline, appsOutline,
  qrCodeOutline, phonePortraitOutline, desktopOutline, cogOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'AppMenu',
  components: {
    ColorModeToggle,
    IonMenu,
    IonMenuToggle,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonFooter,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonIcon
  },
  setup() {
    const userStore = useUserStore();
    const { logged, user } = storeToRefs(userStore);

    return {
      userStore,
      logged,
      user,
      personCircleOutline,
      logOutOutline,
      trashOutline,
      appsOutline,
      qrCodeOutline,
      phonePortraitOutline,
      desktopOutline,
      cogOutline
    };
  }
});
</script>

<style scoped>
  ion-toolbar.toolbar-color-mode {
    --background: transparent;
  }
</style>
