import orderBy from 'lodash/orderBy';

import type { Project } from '@/types/project';
import type { Version } from '@/types/version';
import type { Track } from '@/types/track';
import type { Picture } from '@/types/picture';

const getProjectVersion = (project: Project): Version => {
  const versionsSorted = orderBy(project.versions, ['last_active_at'], ['desc']);
  return versionsSorted[0];
};

const getAllProjectVersionPictures = (version: Version): Array<Picture> => {
  return Object.keys(version.tracks).reduce(
      (acc, trackId) => {
        const picturesToAdd = getAllTrackPictures(version.tracks[trackId]);
        // @ts-ignore
        return acc.concat(picturesToAdd);
      }, []
  )
};

const getAllTrackPictures = (track: Track): Array<Picture> => {
  return Object.keys(track.items).reduce(
    (acc, pictureId) => {
      // @ts-ignore
      acc.push(track.items[pictureId]);
      return acc;
    }, []
  );
};

// https://stackabuse.com/how-to-split-an-array-into-even-chunks-in-javascript/
const spliceIntoChunks = (arr: Array<Picture>, chunkSize: number) => {
  const res = [];
  while (arr.length > 0) {
    const chunk = arr.splice(0, chunkSize);
    res.push(chunk);
  }
  return res;
}

const cleanNullAttributes = (obj: object) => {
  Object.keys(obj).forEach(key => {
    // @ts-ignore
    if (obj[key] === null) {
      // @ts-ignore
      delete obj[key];
    }
  });

  return obj;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
const sortPictures = (pictures: Record<string, Picture>, _sorting: string): Array<Picture> => {
  if (Object.keys(pictures).length === 0) {
    return [];
  }

  const sort = 'position';
  const way = 'asc';

  return orderBy(pictures, [sort], [way]);
}

export default {
  getProjectVersion,
  getAllProjectVersionPictures,
  getAllTrackPictures,
  spliceIntoChunks,
  cleanNullAttributes,
  sortPictures
};
