<template>
  <ion-item :router-link="`/project/${projectId}/${versionId}/track/${track.id}`" button>
    <ion-label class="gp-list-item-sub" slot="end"><ion-text color="primary">{{ new Date(track.inserted_at).toLocaleString() }}</ion-text></ion-label>
    <track-actions :projectId="projectId" :versionId="versionId" :track="track"></track-actions>
    <ion-label>{{ track.title }}</ion-label>
  </ion-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import type { Track } from '@/types/track';

import TrackActions from '@/components/actions/TrackActions.vue';

import {
  IonText,
  IonItem,
  IonLabel,
} from '@ionic/vue';

export default defineComponent({
  name: 'TrackList',
  components: {
    TrackActions,
    IonLabel,
    IonItem,
    IonText
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    versionId: {
      type: String,
      required: true
    },
    track: {
      type: Object as PropType<Track>,
      required: true
    }
  },
  computed: {

  },
});
</script>
