import { environment } from '@/environments/environment';

import { STORAGE_TOKEN } from '@/config/config';
import storage from '../utils/storage';

const getOptions = async (url: string): Promise<any> => {
  const token = await storage.get(STORAGE_TOKEN);
  return {
    url: `${environment.apiBaseUrl}/${url}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  };
}

export default {
  getOptions
};
