<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="cancel()">Cancel</ion-button>
      </ion-buttons>
      <ion-title v-if="!replacedPicture">Add Pictures</ion-title>
      <ion-title v-if="replacedPicture">Replace Picture</ion-title>
      <ion-buttons slot="end">
        <ion-button :strong="true" :disabled="Object.keys(pictures).length === 0" @click="sendPictures()">Send</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-horizontal">
    <ion-grid>
      <ion-row v-if="!this.projectId">
        <ion-col size="10">
          <ion-select interface="popover" :placeholder="Object.keys(projects).length > 0 ? 'Select project' : 'No project has been created'"
            :value="selectedProjectId" @ionChange="setSelectedProjectId($event.detail.value)">
            <ion-select-option v-for="(project, key) in projects" :key="key" :value="key">{{ project.title }}</ion-select-option>
          </ion-select>
        </ion-col>
        <ion-col size="2">
          <ion-button @click="openNewProjectModal()">
            <ion-icon :icon="add"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row v-if="project">
        <ion-col size="10">
          <ion-select interface="popover" :placeholder="Object.keys(project.versions).length > 0 ? 'Select version' : 'No version has been created'"
                      :value="selectedVersionId" @ionChange="setSelectedVersionId($event.detail.value)">
            <ion-select-option v-for="(version, key) in project.versions" :key="key" :value="key">{{ version.title }}</ion-select-option>
          </ion-select>
        </ion-col>
        <ion-col size="2">
          <ion-button @click="openNewVersionModal()">
            <ion-icon :icon="add"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row v-if="project && selectedVersionId && !this.trackId">
        <ion-col size="10">
          <ion-select interface="popover" :placeholder="Object.keys(tracks).length > 0 ? 'Select track' : 'No tracks in this project, please create one first.'"
            :value="selectedTrackId" @ionChange="setSelectedTrackId($event.detail.value)">
            <ion-select-option v-for="(track, key) in tracks" :key="key" :value="key">{{ track.title }}</ion-select-option>
          </ion-select>
        </ion-col>
        <ion-col size="2">
          <ion-button @click="openNewTrackModal()" :disabled="!projectId && !selectedProjectId">
            <ion-icon :icon="add"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12" v-if="Object.keys(pictures).length === 0">
          <div class="ion-text-center ion-margin-vertical"><ion-text>No pictures to upload.</ion-text></div>
        </ion-col>

        <ion-col v-for="(picture, key) in pictures" :key="key" size="12">
          <ion-card>
            <img alt="" :src="picturePath(picture)" />
            <ion-card-content>
              <ion-grid>
                <ion-row>
                  <ion-col size="9">
                    <ion-textarea v-model="models[key]" placeholder="Description" :auto-grow="true">
                    </ion-textarea>
                  </ion-col>
                  <ion-col size="3">
                    <ion-button size="small" @click="removePicture(key)" color="danger">
                      <ion-icon :icon="trashOutline"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
          </ion-card>
        </ion-col>

      </ion-row>
    </ion-grid>

    <ion-button v-if="isNative && (!replacedPicture || (Object.keys(pictures).length === 0))" expand="block" color="light" @click="takePhoto()">
      <ion-icon slot="start" :icon="cameraOutline"></ion-icon>Take more pictures
    </ion-button>
    <ion-button expand="block" color="light" @click="takeMorePhoto()"
      v-if="!replacedPicture || Object.keys(pictures).length === 0">
      <ion-icon slot="start" :icon="isNative ? imageOutline : cameraOutline"></ion-icon>
        <span v-if="!isNative">Add more pictures</span>
        <span v-if="isNative">Add from gallery</span>
    </ion-button>

    <ion-card v-if="(!projectId && !selectedProjectId) && Object.keys(this.pictures).length > 0" color="warning">
      <ion-card-content>
        <ion-icon :icon="alertCircleOutline"></ion-icon>&nbsp;&nbsp;Please create or select a project before uploading your picture(s).
      </ion-card-content>
    </ion-card>

    <ion-card v-if="(projectId || selectedProjectId) && (!trackId && !selectedTrackId) && Object.keys(this.pictures).length > 0" color="warning">
      <ion-card-content>
        <ion-icon :icon="alertCircleOutline"></ion-icon>&nbsp;&nbsp;Please create or select a track before uploading your picture(s).
      </ion-card-content>
    </ion-card>

    <ion-button expand="block" color="success" class="ion-margin-vertical"
      :disabled="sendIsDisabled" @click="sendPictures()">
      <ion-icon slot="start" :icon="sendOutline"></ion-icon>{{ replacedPicture ? 'Replace picture' : 'Send pictures' }}
    </ion-button>

    <ion-grid>
      <ion-row v-if="replacedPicture" class="ion-margin-top">
        <ion-col size="5" class="ion-padding-end">
          <img alt="Replaced picture" :src="replacedPicture.url.thumbnail" />
        </ion-col>
        <ion-col size="7">
              <h5 class="ion-no-margin">Replaced picture</h5>
              <div class="ion-margin-top">Added: {{ new Date(replacedPicture.inserted_at).toLocaleString() }}</div>
              <div>Position: {{ replacedPicture.position }}</div>
        </ion-col>
      </ion-row>
    </ion-grid>

  </ion-content>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue';
import { Capacitor } from '@capacitor/core';
import { storeToRefs } from 'pinia';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

import type { Project } from '@/types/project';
import type { Picture } from '@/types/picture';

import ProjectUtils from '@/utils/ProjectUtils';

import { useProjectsStore } from '@/stores/ProjectsStore';
import { useUserStore } from '@/stores/UserStore';

import ProjectModal from '@/components/modals/ProjectModal.vue';
import VersionModal from '@/components/modals/VersionModal.vue';
import TrackModal from '@/components/modals/TrackModal.vue';

import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonTextarea,
  IonText,
  IonIcon,
  IonSelect,
  IonSelectOption,
  modalController
} from '@ionic/vue';
import { trashOutline, sendOutline, cameraOutline, imageOutline, alertCircleOutline, add } from 'ionicons/icons';

export default defineComponent({
  name: 'NewPictureModal',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonText,
    IonTextarea,
    IonIcon,
    IonSelect,
    IonSelectOption,
  },
  props: {
    projectId: {
      type: String,
      required: false
    },
    versionId: {
      type: String,
      required: false
    },
    trackId: {
      type: String,
      required: false
    },
    replacedPicture: {
      type: Object as PropType<Picture>,
      required: false
    },
  },
  /* eslint-disable indent */
  data(): {
    models: Record<string, any>
    pictures: Record<string, any>
    selectedProjectId: string|null,
    selectedTrackId: string|null,
    isNative: boolean
  } {
    return {
      models: {},
      pictures: {},
      selectedProjectId: null,
      selectedTrackId: null,
      isNative: Capacitor.isNativePlatform()
    }
  },
  setup(props) {
    const projectsStore = useProjectsStore();
    const { projects } = storeToRefs(projectsStore);

    const userStore = useUserStore();
    const { savePicturesToGallery } = storeToRefs(userStore);

    // eslint-disable-next-line vue/no-setup-props-destructure
    const selectedVersionId = ref(props.versionId || null);

    return {
      projectsStore,
      projects,
      selectedVersionId,
      savePicturesToGallery,
      CameraSource,
      trashOutline,
      sendOutline,
      cameraOutline,
      imageOutline,
      alertCircleOutline,
      add
    };
  },
  mounted() {
    this.takePhoto();
  },
  computed: {
    sendIsDisabled() {
      if ((!this.projectId && !this.selectedProjectId)
        || (!this.trackId && !this.selectedTrackId)) {
        return true;
      }

      return Object.keys(this.pictures).length === 0;
    },
    project(): Project|null {
      if (this.projectId) {
        return this.projects[this.projectId];
      }

      if (this.selectedProjectId) {
        return this.projects[this.selectedProjectId];
      }

      return null;
    },
    tracks() {
      // avoid cost
      if (this.trackId) {
        return;
      }

      if (!this.project || !this.selectedVersionId) {
        return {};
      }

      const versionId = this.selectedVersionId || ProjectUtils.getProjectVersion(this.project).id;

      return this.project.versions[versionId].tracks;
    }
  },
  methods: {
    setSelectedProjectId(projectId: string) {
      this.selectedProjectId = projectId;

      // should not happen
      if (this.project === null) {
        this.selectedVersionId = null;
        return;
      }

      this.selectedVersionId = ProjectUtils.getProjectVersion(this.project).id;
    },
    setSelectedVersionId(versionId: string) {
      this.selectedVersionId = versionId;
    },
    setSelectedTrackId(trackId: string) {
        this.selectedTrackId = trackId;
    },
    picturePath(picture: any) {
      return `data:image/${picture.format};base64, ${picture.base64String}`;
    },
    cancel() {
      return modalController.dismiss(null, 'cancel');
    },
    confirm(pictures:Array<string>) {
      return modalController.dismiss(pictures, 'confirm');
    },
    async sendPictures() {
      if (this.sendIsDisabled) {
        return;
      }

      const pictures: Array<string> = [];

      for await (const key of Object.keys(this.pictures)) {
        // Couldn't get $refs to work and get the value

        const imageData = {
          description: this.models[key],
          data: this.pictures[key]
        };

        try {
          // @ts-ignore
          const pictureId = await this.projectsStore.createPicture(this.projectId || this.selectedProjectId, this.selectedVersionId, this.trackId || this.selectedTrackId, imageData, this.replacedPicture ? this.replacedPicture.id : null);
          if (pictureId) {
            pictures.push(pictureId);
          }
        } catch(e) {
          // nothing
        }
      }

      this.confirm(pictures);
    },
    removePicture(refId: string) {
      if (this.pictures[refId]) {
        delete this.pictures[refId];
      }

      if (this.models[refId]) {
        delete this.models[refId];
      }
    },
    async takePhoto(source?: any) {
      if (this.replacedPicture && Object.keys(this.pictures).length === 1) {
        return;
      }

      let image;
      try {
        image = await Camera.getPhoto({
          resultType: CameraResultType.Base64,
          source: source || CameraSource.Camera,
          quality: 100,
          correctOrientation: true,
          // allowEditing: true,
          saveToGallery: this.savePicturesToGallery
        });
      } catch (e) {
        return;
      }

      const refId = new Date().getTime().toString();

      this.models[refId] = ref();
      this.pictures[refId] = image;
    },
    async takeMorePhoto() {
      if (this.isNative) {
        return this.takePhoto(CameraSource.Photos)
      }

      return this.takePhoto();
    },
    async openNewProjectModal() {
      const modal = await modalController.create({
        component: ProjectModal
      });

      modal.present();
      const { data } = await modal.onWillDismiss();

      if (data) {
        this.selectedProjectId = data;
      }
    },
    async openNewVersionModal() {
      const modal = await modalController.create({
        component: VersionModal
      });

      modal.present();
      const { data } = await modal.onWillDismiss();

      if (data) {
        this.selectedVersionId = data;
      }
    },
    async openNewTrackModal() {
      if (!this.projectId && !this.selectedProjectId) {
        return;
      }

      const versionId = this.selectedVersionId || ProjectUtils.getProjectVersion(this.projects[(this.projectId || this.selectedProjectId) as string]).id;

      const modal = await modalController.create({
        component: TrackModal,
        componentProps: {
          projectId: this.selectedProjectId || this.projectId,
          versionId: versionId
        }
      });

      modal.present();
      const { data } = await modal.onWillDismiss();

      if (data) {
        this.selectedTrackId = data;
      }
    },
  },
});
</script>
