<template>
  <ion-button @click.prevent="presentActionSheet()" fill="clear" slot="end" size="default" style="--padding-end: 0;">
    <ion-icon :icon="cogOutline"></ion-icon>
  </ion-button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { storeToRefs } from 'pinia';

import type { Picture } from '@/types/picture';

import EditPictureModal from '@/components/modals/EditPictureModal.vue';
import NewPictureModal from '@/components/modals/NewPictureModal.vue';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { useUserStore } from '@/stores/UserStore';

import {
  IonButton,
  IonIcon,
  actionSheetController,
  alertController,
  modalController,
} from '@ionic/vue';
import { cogOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'PictureActions',
  components: {
    IonButton,
    IonIcon
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    versionId: {
      type: String,
      required: true
    },
    trackId: {
      type: String,
      required: true
    },
    picture: {
      type: Object as PropType<Picture>,
      required: true
    },
    gotoUrlIfDeleted: {
      type: String,
      required: false
    }
  },
  setup() {
    const projectsStore = useProjectsStore();
    const userStore = useUserStore();
    const { isReadOnly } = storeToRefs(userStore);

    return {
      projectsStore,
      isReadOnly,
      // router,
      cogOutline
    };
  },
  methods: {
    async presentActionSheet() {
      const actionSheet = await actionSheetController.create({
        header: 'Actions',
        cssClass: "gp-action-sheet",
        buttons: [
          {
            text: 'Edit picture',
            cssClass: this.isReadOnly ? 'action-sheet-disabled' : '',
            data: {
              action: 'edit',
            },
            handler: () => {
              if (this.isReadOnly) {
                return false;
              }

              this.openEditModal();
            }
          },
          {
            text: 'Replace picture',
            cssClass: this.isReadOnly ? 'action-sheet-disabled' : '',
            data: {
              action: 'replace',
            },
            handler: () => {
              if (this.isReadOnly) {
                return false;
              }

              this.openReplaceModal();
            }
          },
          {
            text: 'Delete picture',
            role: 'destructive',
            data: {
              action: 'delete',
            },
            handler: async () => {
              const alert = await alertController.create({
                header: 'Deletion confirmation',
                message: 'Are you sure you want to delete this picture?',
                buttons: [
                  {
                    text: 'YES',
                    role: 'confirm',
                    cssClass: 'alert-button-confirm',
                  },
                  {
                    text: 'NO',
                    role: 'cancel',
                    cssClass: 'alert-button-danger',
                  }
                ],
              });

              await alert.present();

              const { role } = await alert.onDidDismiss();
              if (role === 'confirm') {
                await this.projectsStore.deletePicture(this.projectId, this.versionId, this.trackId, this.picture, this.gotoUrlIfDeleted);
              }
            }
          },
          {
            text: 'Cancel',
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          },
        ],
      });

      await actionSheet.present();
      await actionSheet.onDidDismiss();
    },
    async openEditModal() {
      const modal = await modalController.create({
        component: EditPictureModal,
        componentProps: {
          projectId: this.projectId,
          versionId: this.versionId,
          trackId: this.trackId,
          picture: this.picture
        }
      });
      modal.present();
    },
    async openReplaceModal() {
      const modal = await modalController.create({
        component: NewPictureModal,
        componentProps: {
          projectId: this.projectId,
          versionId: this.versionId,
          trackId: this.trackId,
          replacedPicture: this.picture
        }
      });
      modal.present();
    },
  }
});
</script>

<style>
ion-action-sheet.gp-action-sheet {
  --backdrop-opacity: 0.7;
}
</style>