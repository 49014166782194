import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_project_actions = _resolveComponent("project-actions")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    "router-link": `/project/${_ctx.project.id}`,
    button: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, {
        class: "gp-list-item-sub",
        slot: "end"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_text, { color: "primary" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(new Date(_ctx.project.inserted_at).toLocaleDateString()), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_project_actions, {
        project: _ctx.project,
        version: _ctx.version
      }, null, 8, ["project", "version"]),
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.project.title), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["router-link"]))
}