<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Preferences</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-list>
              <ion-list-header class="ion-padding-bottom">
                <ion-label>Projects pages</ion-label>
              </ion-list-header>
              <ion-item lines="none">
                <ion-toggle :checked="displayBreadcrumbs" @ionChange="breadcrumbsChanged($event)"
                  label-placement="end" justify="start">
                  Display breadcrumbs
                </ion-toggle>
              </ion-item>
              <ion-item lines="none">
                <ion-toggle :checked="displayVersionToolbar" @ionChange="versionChanged($event)"
                  label-placement="end" justify="start">
                  Always display the version toolbar
                </ion-toggle>
              </ion-item>
              <ion-item lines="none">
                <ion-toggle :checked="reopenAtLaunch" @ionChange="reopenChanged($event)"
                  label-placement="end" justify="start" disabled>
                  Reopen the last page when launching<br>the app
                </ion-toggle>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>

        <ion-row v-if="isNative">
          <ion-col>
            <ion-list>
              <ion-list-header class="ion-padding-bottom">
                <ion-label>Pictures</ion-label>
              </ion-list-header>
              <ion-item lines="none">
                <ion-toggle :checked="savePicturesToGallery" @ionChange="savePicturesToGalleryChanged($event)"
                  label-placement="end" justify="start">
                  Save to device gallery
                </ion-toggle>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ion-list>
              <ion-list-header class="ion-padding-bottom">
                <ion-label>UI</ion-label>
              </ion-list-header>
              <ion-item lines="none">
                <color-mode-toggle :labels="true"></color-mode-toggle>
              </ion-item>
              <ion-item class="ion-padding-top" v-if="!isNative" lines="none">
                <ui-mode-toggle></ui-mode-toggle>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Capacitor } from '@capacitor/core';
import { storeToRefs } from 'pinia';

import { useUserStore } from '@/stores/UserStore';

import ColorModeToggle from '@/components/ColorModeToggle.vue';
import UiModeToggle from '@/components/UiModeToggle.vue';

import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonButtons,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonToggle,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
} from '@ionic/vue';

export default defineComponent({
  name: 'PreferencesPage',
  components: {
    ColorModeToggle,
    UiModeToggle,
    IonLabel,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonGrid,
    IonRow,
    IonCol,
    IonToggle,
    IonList,
    IonListHeader,
    IonItem
  },
  /* eslint-disable indent */
  data(): {
    isNative: boolean
  } {
    return {
      isNative: Capacitor.isNativePlatform()
    }
  },
  setup() {
    const userStore = useUserStore();
    const {
      displayBreadcrumbs,
      displayVersionToolbar,
      reopenAtLaunch,
      savePicturesToGallery
    } = storeToRefs(userStore);

    return {
      userStore,
      displayBreadcrumbs,
      displayVersionToolbar,
      savePicturesToGallery,
      reopenAtLaunch
    };
  },
  computed: {

  },
  methods: {
    versionChanged(event: any) {
      this.userStore.setDisplayVersionToolbar(event.detail.checked);
    },
    breadcrumbsChanged(event: any) {
      this.userStore.setDisplayBreadcrumbs(event.detail.checked);
    },
    reopenChanged(event: CustomEvent) {
      this.userStore.setReopenAtLaunch(event.detail.checked);
    },
    savePicturesToGalleryChanged(event: CustomEvent) {
      this.userStore.setSavePicturesToGallery(event.detail.checked);
    }
  }
});
</script>
