
export const environment = {
    baseUrl: 'https://www.gearpix.app',
    baseCdn: 'https://gearpix.b-cdn.net/',
    socketUrl: 'wss://www.gearpix.app/socket',
    apiBaseUrl: 'https://www.gearpix.app/api/v1',
    firebase: {
        apiKey: 'AIzaSyABr8o-GevIcbOn5gfR2tpZxG8-YxCTXKY',
        authDomain: 'gearspix-prod.firebaseapp.com',
        projectId: 'gearspix-prod',
        storageBucket: '',
        messagingSenderId: '757151361223'
    },
};
