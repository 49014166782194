<template>
  <ion-button @click.prevent="presentActionSheet()" fill="clear" slot="end" size="small">
    <ion-icon :icon="cogOutline"></ion-icon>
  </ion-button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { storeToRefs } from 'pinia';

import type { Project } from '@/types/project';
import type { Version } from '@/types/version';

import VersionModal from '@/components/modals/VersionModal.vue';
import { useProjectsStore } from '@/stores/ProjectsStore';
import { useUserStore } from '@/stores/UserStore';

import {
  IonButton,
  IonIcon,
  actionSheetController,
  alertController,
  modalController
} from '@ionic/vue';
import { cogOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'VersionActions',
  components: {
    IonButton,
    IonIcon
  },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true
    },
    version: {
      type: Object as PropType<Version>,
      required: true
    },
    gotoUrlIfDeleted: {
      type: String,
      required: false
    }
  },
  setup() {
    // const router = useIonRouter();
    const projectsStore = useProjectsStore();

    const userStore = useUserStore();
    const { isReadOnly } = storeToRefs(userStore);

    return {
      projectsStore,
      isReadOnly,
      // router,
      cogOutline
    };
  },
  methods: {
    async presentActionSheet() {
      // can't set an action as disabled so if not enough versions we remove the delete action
      const actions = [
          {
            text: 'Create new empty version',
            cssClass: this.isReadOnly ? 'action-sheet-disabled' : '',
            data: {
              action: 'edit',
            },
            handler: () => {
              if (this.isReadOnly) {
                return false;
              }

              this.openModal(true);
            }
          },
          {
            text: 'Clone current version',
            cssClass: this.isReadOnly ? 'action-sheet-disabled' : '',
            data: {
              action: 'edit',
            },
            handler: () => {
              if (this.isReadOnly) {
                return false;
              }

              this.openModal(true, this.version);
            }
          },
          {
            text: 'Edit current version',
            cssClass: this.isReadOnly ? 'action-sheet-disabled' : '',
            data: {
              action: 'edit',
            },
            handler: () => {
              if (this.isReadOnly) {
                return false;
              }

              this.openModal(false);
            }
          },
        ];

      if (Object.keys(this.project.versions).length > 1) {
        actions.push({
          text: 'Delete version',
          role: 'destructive',
          data: {
            action: 'delete',
          },
          // @ts-ignore
          handler: async () => {
            const alert = await alertController.create({
              header: 'Deletion confirmation',
              subHeader: this.version.title,
              message: 'Are you sure you want to delete this version?',
              buttons: [
                {
                  text: 'YES',
                  role: 'confirm',
                  cssClass: 'alert-button-confirm',
                },
                {
                  text: 'NO',
                  role: 'cancel',
                  cssClass: 'alert-button-danger',
                }
              ],
            });

            await alert.present();

            const { role } = await alert.onDidDismiss();
            if (role === 'confirm') {
              this.projectsStore.deleteVersion(this.project.id, this.version, this.gotoUrlIfDeleted);
            }
          }
        });
      }

      actions.push({
        text: 'Cancel',
        // @ts-ignore
        role: 'cancel',
        data: {
          action: 'cancel',
        },
      });

      const actionSheet = await actionSheetController.create({
        header: 'Actions',
        cssClass: "gp-action-sheet",
        buttons: actions
      });

      await actionSheet.present();
      await actionSheet.onDidDismiss();
    },
    async openModal(newVersion: boolean, cloneVersion?: Version) {
      const modal = await modalController.create({
        component: VersionModal,
        componentProps: {
          project: this.project,
          version: newVersion ? null : this.version,
          cloneVersion: newVersion && cloneVersion ? cloneVersion : null
        }
      });
      modal.present();
    }
  }
});
</script>

<style scoped>
  /* aligned with top toolbar button */
  ion-button.ios {
    --padding-end: 5px;
  }
</style>
