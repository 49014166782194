<template>
  <ion-card :router-link="`/project/${projectId}/${versionId}/track/${track.id}`">
    <img v-if="pictures.length === 0"
         alt="No Pix" src="/assets/nopix.jpg" />
    <swiper
        v-if="pictures.length > 0"
        :autoplay="true"
        :initialSlide="0"
        :data-swiper-autoplay="2000"
        :rewind="true"
        :slides-per-view="1"
        :modules="modules"
    >
      <swiper-slide v-for="picture in pictures" :key="picture.id">
        <img alt="" :src="picture.url.thumbnail" />
      </swiper-slide>
    </swiper>
    <ion-card-header>
      <ion-card-title>{{ track.title }}</ion-card-title>
      <ion-card-subtitle>
        Created on {{ new Date(track.inserted_at).toLocaleDateString([], {hour: '2-digit', minute:'2-digit'}) }}
      </ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      <div class="content-wrapper">
        <div></div>
        <div class="content-actions">
          <div>{{ Object.keys(track.items).length }} pictures</div>
          <div>
            <track-actions :projectId="projectId" :versionId="versionId" :track="track"></track-actions>
          </div>
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/autoplay';

import { useProjectsStore } from '@/stores/ProjectsStore';
import ProjectUtils from '@/utils/ProjectUtils';

import TrackActions from '@/components/actions/TrackActions.vue';

import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from '@ionic/vue';

export default defineComponent({
  name: 'TrackCard',
  components: {
    TrackActions,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    Swiper,
    SwiperSlide,
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    versionId: {
      type: String,
      required: true
    },
    trackId: {
      type: String,
      required: true
    }
  },
  setup() {
    const projectsStore = useProjectsStore();
    const { projects } = storeToRefs(projectsStore);

    return {
      modules: [Autoplay],
      projects
    };
  },
  computed: {
    track() {
      return this.projects[this.projectId].versions[this.versionId].tracks[this.trackId];
    },
    pictures() {
      return ProjectUtils.getAllTrackPictures(this.track);
    }
  },
});
</script>
