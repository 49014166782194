<template>
  <ion-card v-if="project" :router-link="`/project/${project.id}/${version.id}`">
    <img v-if="getPicturesOfProject.length === 0"
         alt="No Pix" src="/assets/nopix.jpg" />
    <swiper
        v-if="getPicturesOfProject.length > 0"
        :autoplay="true"
        :initialSlide="0"
        :data-swiper-autoplay="2000"
        :rewind="true"
        :slides-per-view="1"
        :modules="modules"
    >
      <swiper-slide v-for="picture in getPicturesOfProject" :key="picture.id">
        <img alt="" :src="picture.url.thumbnail" />
      </swiper-slide>
    </swiper>
    <ion-card-header>
      <ion-card-title>{{ project.title }}</ion-card-title>
      <ion-card-subtitle>
        Created on {{ new Date(project.inserted_at).toLocaleDateString([], {hour: '2-digit', minute:'2-digit'}) }}
      </ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      <div class="content-wrapper">
        <div></div>
        <div class="content-actions">
          <div><!-- {{ Object.keys(project.versions).length }} versions --></div>
          <div>
            <project-actions :project="project" :version="version"></project-actions>
          </div>
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/autoplay';

import type { Project } from '@/types/project';
import type { Version } from '@/types/version';

import { useProjectsStore } from '@/stores/ProjectsStore';
import ProjectUtils from '@/utils/ProjectUtils';

import ProjectActions from '@/components/actions/ProjectActions.vue';

import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from '@ionic/vue';

export default defineComponent({
  name: 'ProjectCard',
  components: {
    ProjectActions,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    Swiper,
    SwiperSlide,
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    versionId: {
      type: String,
      required: false
    },
  },
  setup() {
    const projectsStore = useProjectsStore();
    const { projects } = storeToRefs(projectsStore);

    return {
      modules: [Autoplay],
      projects
    };
  },
  computed: {
    project(): Project {
      return this.projects[this.projectId];
    },
    version(): Version {
      if (this.versionId) {
        return this.project.versions[this.versionId];
      }

      return ProjectUtils.getProjectVersion(this.project);
    },
    getPicturesOfProject() {
      return ProjectUtils.getAllProjectVersionPictures(this.version);
    }
  },
});
</script>
