import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7865d51a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-margin-vertical" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_project_actions = _resolveComponent("project-actions")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_read_only_alert = _resolveComponent("read-only-alert")!
  const _component_breadcrumbs_toolbar = _resolveComponent("breadcrumbs-toolbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_version_actions = _resolveComponent("version-actions")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_pull_to_refresh = _resolveComponent("pull-to-refresh")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_track_list = _resolveComponent("track-list")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_track_card = _resolveComponent("track-card")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab_list = _resolveComponent("ion-fab-list")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      (_ctx.project && _ctx.version)
        ? (_openBlock(), _createBlock(_component_ion_header, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, { slot: "start" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_back_button, { "default-href": "/home" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_buttons, { slot: "end" }, {
                    default: _withCtx(() => [
                      (!_ctx.isReadOnly && _ctx.mode === 'ios')
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 0,
                            onClick: _ctx.openNewPictureModal
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.cameraOutline }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (!_ctx.isReadOnly && _ctx.mode === 'ios')
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 1,
                            onClick: _ctx.openModal
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_project_actions, {
                        project: _ctx.project,
                        version: _ctx.version,
                        "goto-url-if-deleted": '/'
                      }, null, 8, ["project", "version"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Project")
                    ]),
                    _: 1
                  }),
                  (_ctx.loading === true)
                    ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
                        key: 0,
                        type: "indeterminate"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_read_only_alert),
              (_ctx.displayBreadcrumbs)
                ? (_openBlock(), _createBlock(_component_breadcrumbs_toolbar, {
                    key: 0,
                    projectId: _ctx.project.id,
                    versionId: _ctx.version.id
                  }, null, 8, ["projectId", "versionId"]))
                : _createCommentVNode("", true),
              (_ctx.loading === false && (_ctx.versionToolbar === true || _ctx.displayVersionToolbar))
                ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                    key: 1,
                    class: "version-toolbar"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, {
                        slot: "start",
                        class: "ion-padding-start"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Versions")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select, {
                        interface: "popover",
                        slot: "end",
                        value: _ctx.version.id,
                        onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setVersion($event.detail.value)))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.versions, (v, key) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: key,
                              value: v.id
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(v.title), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value"]),
                      (_ctx.hasFeature('versioning'))
                        ? (_openBlock(), _createBlock(_component_version_actions, {
                            key: 0,
                            project: _ctx.project,
                            version: _ctx.version,
                            "goto-url-if-deleted": `/project/${_ctx.id}`
                          }, null, 8, ["project", "version", "goto-url-if-deleted"]))
                        : _createCommentVNode("", true),
                      (!_ctx.hasFeature('versioning'))
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 1,
                            id: "version-click-trigger",
                            fill: "clear",
                            size: "small",
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                slot: "icon-only",
                                icon: _ctx.informationCircleOutline
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.hasFeature('versioning'))
                        ? (_openBlock(), _createBlock(_component_ion_popover, {
                            key: 2,
                            trigger: "version-click-trigger",
                            "trigger-action": "click"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_content, { class: "ion-padding" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Versioning is not active with your current subscription plan.")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.loading === false)
                ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 2 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_buttons, { slot: "start" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setViewMode('card')))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                slot: "icon-only",
                                icon: _ctx.gridOutline,
                                color: _ctx.viewMode === 'card' ? 'secondary' : ''
                              }, null, 8, ["icon", "color"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_button, {
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setViewMode('list')))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: _ctx.listOutline,
                                color: _ctx.viewMode === 'list' ? 'secondary' : ''
                              }, null, 8, ["icon", "color"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (!_ctx.displayVersionToolbar)
                        ? (_openBlock(), _createBlock(_component_ion_title, {
                            key: 0,
                            color: "medium"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleVersionToolbar())),
                                fill: "clear",
                                size: "small"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "icon-only",
                                    icon: _ctx.gitMergeOutline,
                                    color: _ctx.versionToolbar ? 'secondary' : ''
                                  }, null, 8, ["icon", "color"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_select, {
                        interface: "popover",
                        slot: "end",
                        value: _ctx.sortBy,
                        onIonChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setSortBy($event.detail.value)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select_option, { value: "last_active_at_desc" }, {
                            default: _withCtx(() => [
                              _createTextVNode("By last active")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "inserted_at_desc" }, {
                            default: _withCtx(() => [
                              _createTextVNode("By creation date desc.")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "title_asc" }, {
                            default: _withCtx(() => [
                              _createTextVNode("By name asc.")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.project && _ctx.version)
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_pull_to_refresh),
              (Object.keys(_ctx.version.tracks).length === 0 && !_ctx.creating)
                ? (_openBlock(), _createBlock(_component_ion_grid, {
                    key: 0,
                    class: "project-grid"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, { class: "ion-align-items-center ion-justify-content-center ion-align-items-baseline" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, { class: "ion-align-self-center ion-text-center" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_1, [
                                _createVNode(_component_ion_label, { class: "ion-text-center" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" There is no tracks in this project. ")
                                  ]),
                                  _: 1
                                })
                              ]),
                              (!_ctx.isReadOnly)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                    _createVNode(_component_ion_button, { onClick: _ctx.openModal }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" Add a new track ")
                                      ]),
                                      _: 1
                                    }, 8, ["onClick"])
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.viewMode === 'list')
                ? (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tracksOrdered, (track, key) => {
                        return (_openBlock(), _createBlock(_component_track_list, {
                          key: key,
                          projectId: _ctx.id,
                          versionId: _ctx.version.id,
                          track: track
                        }, null, 8, ["projectId", "versionId", "track"]))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.viewMode === 'card')
                ? (_openBlock(), _createBlock(_component_ion_grid, {
                    key: 2,
                    class: "project-grid"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tracksOrdered, (track, key) => {
                            return (_openBlock(), _createBlock(_component_ion_col, {
                              key: key,
                              size: "6"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_track_card, {
                                  trackId: track.id,
                                  projectId: (_ctx.id as string),
                                  versionId: _ctx.version.id
                                }, null, 8, ["trackId", "projectId", "versionId"])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128)),
                          (_ctx.creating)
                            ? (_openBlock(), _createBlock(_component_ion_col, {
                                key: 0,
                                size: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_thumbnail, { style: {"width":"100%","height":"74px"} }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_skeleton_text, { animated: true })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_card_header, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_skeleton_text, {
                                            animated: true,
                                            style: {"width":"90%"}
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_card_content, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_skeleton_text, {
                                            animated: true,
                                            style: {"width":"75%"}
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!_ctx.isReadOnly && _ctx.mode !== 'ios')
                ? (_openBlock(), _createBlock(_component_ion_fab, {
                    key: 3,
                    vertical: "bottom",
                    horizontal: "end",
                    slot: "fixed"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_fab_button, { color: "secondary" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_fab_list, { side: "top" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_fab_button, {
                            color: "secondary",
                            onClick: _ctx.openNewPictureModal,
                            "data-desc": "Take picture"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.cameraOutline }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_ion_fab_button, {
                            color: "secondary",
                            onClick: _ctx.openModal,
                            "data-desc": "Create track"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}