import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Cancel")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode("Edit Picture")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  strong: true,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirm()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Edit")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { class: "ion-padding" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, { position: "stacked" }, {
              default: _withCtx(() => [
                _createTextVNode("Picture description")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_textarea, {
              modelValue: _ctx.description,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.description) = $event)),
              modelModifiers: { trim: true },
              autofocus: true,
              placeholder: "Description",
              "auto-grow": true
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}