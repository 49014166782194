<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="cancel()">Cancel</ion-button>
      </ion-buttons>
      <ion-title>Edit Picture</ion-title>
      <ion-buttons slot="end">
        <ion-button :strong="true" @click="confirm()">Edit</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-item>
      <ion-label position="stacked">Picture description</ion-label>
      <ion-textarea v-model.trim="description" :autofocus="true"
        placeholder="Description" :auto-grow="true"></ion-textarea>
    </ion-item>
  </ion-content>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import type { PropType } from 'vue';
import { storeToRefs } from 'pinia';

import type { Picture } from '@/types/picture';

import { useProjectsStore } from '@/stores/ProjectsStore';

import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonItem,
    IonLabel,
    IonTextarea,
    modalController
} from '@ionic/vue';

export default defineComponent({
  name: 'EditPictureModal',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonItem,
    IonLabel,
    IonTextarea
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    versionId: {
      type: String,
      required: true
    },
    trackId: {
      type: String,
      required: true
    },
    picture: {
      type: Object as PropType<Picture>,
      required: true
    }
  },
  setup(props) {
    const projectsStore = useProjectsStore();
    const { creating } = storeToRefs(projectsStore);

    // eslint-disable-next-line vue/no-setup-props-destructure
    const description = ref(props.picture ? props.picture.description : null);

    return {
      projectsStore,
      creating,
      description
    };
  },
  computed: {
    submittable() {
      return this.description !== undefined && this.description !== null && this.description !== '';
    }
  },
  methods: {
    cancel() {
      return modalController.dismiss(null, 'cancel');
    },
    confirm() {
      if (this.creating) {
        return;
      }

      if (!this.description || this.description === '') {
        return;
      }

      this.projectsStore.editPicture(this.projectId, this.versionId, this.trackId, this.picture, this.description);

      return modalController.dismiss(this.description, 'confirm');
    },
  },
});
</script>
