<template>
  <ion-page>
    <ion-content color="dark">
      <swiper
          :initialSlide="initialSlide"
          :rewind="true"
          :zoom="true"
          :slides-per-view="1"
          :space-between="50"
          :modules="modules"
          :pagination="{
            dynamicBullets: true,
          }"
      >
        <swiper-slide v-for="(picture, key) in pictures" :key="key">
          <div class="swiper-zoom-container">
            <ion-button fill="clear" @click="close()" style="z-index: 2; position: fixed; top: 10px;right: 0px;">
              <ion-icon slot="icon-only" color="light" :icon="closeOutline"></ion-icon>
            </ion-button>
            <div v-if="picture.description" class="swiper-text">
              {{ picture.description }}
            </div>
            <img alt="" :src="picture.url.full" />
          </div>
        </swiper-slide>
      </swiper>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Zoom } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

import { useProjectsStore } from '@/stores/ProjectsStore';

import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  useIonRouter
} from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'PicturesPage',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonIcon,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const router = useIonRouter();
    const route = useRoute();
    const projectsStore = useProjectsStore();

    const { projectId, versionId, trackId, pictureId } = route.params;

    return {
      router,
      modules: [Pagination, Zoom],
      projectsStore,
      projectId,
      versionId,
      trackId,
      pictureId,
      closeOutline
    };
  },
  computed: {
    pictures() {
      if (this.projectsStore.projects[this.projectId as string] === undefined
          || this.projectsStore.projects[this.projectId as string].versions[this.versionId as string] === undefined
          || this.projectsStore.projects[this.projectId as string].versions[this.versionId as string].tracks[this.trackId as string] === undefined) {
        return null;
      }

      return this.projectsStore.projects[this.projectId as string].versions[this.versionId as string].tracks[this.trackId as string].items;
    },
    initialSlide() {
      if (!this.pictures || this.pictureId === undefined || this.pictureId === null) {
        return 0;
      }

      const keys = Object.keys(this.pictures);
      const index = keys.indexOf(this.pictureId as string);

      return index !== -1 ? index : 0;
    }
  },
  methods: {
    close() {
      // if can't go back (page reload ?) we avoid the spa routing
      // because the "back menu" would loop to here
      if (this.router.canGoBack()) {
        this.router.back();
      } else {
        location.assign(`/project/${this.projectId}/${this.versionId}/track/${this.trackId}`)
      }
    }
  }
});
</script>
